import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Carrousel from '../Carrousel'

const MapCard = ({ place, onClick, userPreferences }) => {
	const { properties } = place
	const { title, tags, pictures } = properties
	const hasPictures =
		properties.pictures && Object.keys(properties.pictures).length > 0

	const [selectedTags, setSelectedTags] = useState(
		userPreferences.preferences || []
	)

	useEffect(() => {
		setSelectedTags(userPreferences.preferences || [])
	}, [userPreferences])

	// Trier les tags en plaçant les préférés de l'utilisateur en premier
	const sortedTags = tags.slice().sort((a, b) => {
		const aIsPreferred = selectedTags.includes(a)
		const bIsPreferred = selectedTags.includes(b)
		return aIsPreferred === bIsPreferred ? 0 : aIsPreferred ? -1 : 1
	})

	// Afficher uniquement 4 tags maximum
	const tagsToDisplay = sortedTags.slice(0, 3)

	return (
		<div className="mapCard" onClick={onClick}>
			<h4>{title}</h4>
			<div className="mapCard__tags">
				{tagsToDisplay.length > 0 ? (
					tagsToDisplay.map((tag, index) => (
						<span
							key={index}
							className={`mapCard__tag ${
								selectedTags.includes(tag) ? 'selected' : ''
							}`}
						>
							{tag}
						</span>
					))
				) : (
					<span>Aucun tag disponible</span>
				)}
				{hasPictures ? (
					<Carrousel
						title={title}
						pictures={pictures}
						id={place.id}
						reduceView={true}
					/>
				) : (
					<div className="placeCard__no-images">
						Pas encore de photo pour ce lieu.
					</div>
				)}
			</div>
		</div>
	)
}

MapCard.propTypes = {
	place: PropTypes.object.isRequired,
	onClick: PropTypes.func,
	userPreferences: PropTypes.shape({
		preferences: PropTypes.array
	}).isRequired
}

export default MapCard
