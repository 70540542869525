import { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import Map, { Source, Layer, AttributionControl } from 'react-map-gl'

import MarkerMap from '../Map-Marker'
import MapCard from '../Map-Card'

const MapMain = ({
	data,
	dataEvent,
	setActiveMenu,
	setActiveTab,
	setSelectedPlaceId,
	setSelectedEventId,
	highlightedPlaceId,
	highlightedEventId,
	onBoundsChange,
	userLocation,
	userPreferences,
	centerOnPlaceTrigger
}) => {
	const mapRef = useRef(null)
	const tooltipRef = useRef(null)
	const [currentZoom, setCurrentZoom] = useState(12)
	const [attributionConfig, setAttributionConfig] = useState({
		position: 'bottom-right',
		compact: false
	})
	const zoomThreshold = 12.5

	// États pour le tooltip et son alignement
	const [hoveredPlace, setHoveredPlace] = useState(null)
	const [hoveredEvent, setHoveredEvent] = useState(null)
	const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })
	// tooltipAlign : horizontal: 'center' | 'left' | 'right' ; vertical: 'top' | 'bottom'
	const [tooltipAlign, setTooltipAlign] = useState({
		horizontal: 'center',
		vertical: 'top'
	})

	// Position par défaut (Le Quesnoy)
	const defaultLocation = {
		userLat: 50.247342,
		userLon: 3.63645
	}

	useEffect(() => {
		const updateAttributionConfig = () => {
			if (window.innerWidth < 768) {
				setAttributionConfig({ position: 'top-left', compact: true })
			} else {
				setAttributionConfig({
					position: 'bottom-right',
					compact: false
				})
			}
		}
		updateAttributionConfig()
		window.addEventListener('resize', updateAttributionConfig)
		return () =>
			window.removeEventListener('resize', updateAttributionConfig)
	}, [])

	const centerOnPlace = (longitude, latitude) => {
		if (mapRef.current && longitude && latitude) {
			const map = mapRef.current.getMap()
			const isSmallScreen = window.innerWidth < 768
			const offset = isSmallScreen ? [0, -175] : [0, 0]
			map.flyTo({
				center: [longitude, latitude],
				zoom: 12.5,
				speed: 1.2,
				bearing: 0,
				essential: true,
				offset: offset
			})
		}
	}

	const handleBoundsChange = useCallback(() => {
		if (mapRef.current) {
			const map = mapRef.current.getMap()
			const bounds = map.getBounds()
			const boundsData = {
				northEast: bounds.getNorthEast().toArray(),
				southWest: bounds.getSouthWest().toArray()
			}
			onBoundsChange(boundsData)
		}
	}, [onBoundsChange])

	useEffect(() => {
		if (mapRef.current) {
			const map = mapRef.current.getMap()
			handleBoundsChange()
			map.on('moveend', handleBoundsChange)
			return () => map.off('moveend', handleBoundsChange)
		}
	}, [handleBoundsChange])

	useEffect(() => {
		if (highlightedPlaceId) {
			const placeToCenter = data.find(
				(place) => place.id === highlightedPlaceId
			)
			if (placeToCenter) {
				const [longitude, latitude] = placeToCenter.geometry.coordinates
				centerOnPlace(longitude, latitude)
			}
		}
	}, [centerOnPlaceTrigger, highlightedPlaceId, data])

	useEffect(() => {
		if (highlightedEventId) {
			const eventToCenter = dataEvent.find(
				(event) => event.id === highlightedEventId
			)
			if (eventToCenter) {
				const [longitude, latitude] = eventToCenter.geometry.coordinates
				centerOnPlace(longitude, latitude)
			}
		}
	}, [centerOnPlaceTrigger, highlightedEventId, dataEvent])

	useEffect(() => {
		if (userLocation && userLocation.userLon && userLocation.userLat) {
			centerOnPlace(userLocation.userLon, userLocation.userLat)
		}
	}, [userLocation])

	const handleZoomChange = () => {
		if (mapRef.current) {
			const map = mapRef.current.getMap()
			setCurrentZoom(map.getZoom())
		}
	}

	const mapViewLocation =
		userLocation && userLocation.userLon && userLocation.userLat
			? userLocation
			: defaultLocation

	// Fusionner places et events dans une seule source GeoJSON en ajoutant une propriété "type"
	const combinedData = useMemo(
		() => ({
			type: 'FeatureCollection',
			features: [
				...data.map((feature) => ({
					...feature,
					properties: { ...feature.properties, type: 'place' }
				})),
				...dataEvent.map((feature) => ({
					...feature,
					properties: { ...feature.properties, type: 'event' }
				}))
			]
		}),
		[data, dataEvent]
	)

	// Couches de clustering pour la source combinée
	const clusterLayerCombined = {
		id: 'clusters',
		type: 'circle',
		source: 'combined',
		filter: ['has', 'point_count'],
		paint: {
			'circle-color': '#d8b458',
			'circle-radius': [
				'step',
				['get', 'point_count'],
				20,
				100,
				30,
				750,
				40
			],
			'circle-stroke-width': 2,
			'circle-stroke-color': '#fff'
		}
	}

	const clusterCountLayerCombined = {
		id: 'cluster-count',
		type: 'symbol',
		source: 'combined',
		filter: ['has', 'point_count'],
		layout: {
			'text-field': '{point_count_abbreviated}',
			'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
			'text-size': 12
		}
	}

	const unclusteredLayerCombined = {
		id: 'unclustered-point',
		type: 'circle',
		source: 'combined',
		filter: ['!', ['has', 'point_count']],
		paint: {
			'circle-color': '#d8b458',
			'circle-radius': 10,
			'circle-stroke-width': 2,
			'circle-stroke-color': '#fff'
		}
	}

	// Gestion du clic sur un cluster dans la source combinée
	const handleClusterClick = (event) => {
		const map = mapRef.current.getMap()
		if (currentZoom >= zoomThreshold) return
		const layersToQuery = ['clusters', 'unclustered-point']
		const availableLayers = layersToQuery.filter((layerId) =>
			map.getLayer(layerId)
		)
		if (availableLayers.length === 0) {
			console.warn("Aucune couche de cluster n'est disponible.")
			return
		}
		const features = map.queryRenderedFeatures(event.point, {
			layers: availableLayers
		})
		if (features.length) {
			const feature = features[0]
			if (feature.layer.id === 'clusters') {
				const clusterId = feature.properties.cluster_id
				map.getSource('combined').getClusterExpansionZoom(
					clusterId,
					(err, zoom) => {
						if (err) return
						const [longitude, latitude] =
							feature.geometry.coordinates
						map.easeTo({
							center: [longitude, latitude],
							zoom: 12.5,
							duration: 500
						})
					}
				)
			} else if (feature.layer.id === 'unclustered-point') {
				const [longitude, latitude] = feature.geometry.coordinates
				map.easeTo({
					center: [longitude, latitude],
					zoom: 12.5,
					duration: 500
				})
			}
		} else {
			console.warn("Aucune couche de cluster n'est disponible.")
		}
	}

	// Gestion du survol pour les markers de type "place"
	const handleMarkerEnterPlace = (place) => {
		if (mapRef.current) {
			const map = mapRef.current.getMap()
			const [longitude, latitude] = place.geometry.coordinates
			const screenPos = map.project([longitude, latitude])
			const viewportWidth = window.innerWidth
			let horizontal = 'center'
			if (screenPos.x > viewportWidth - 450) {
				horizontal = 'left'
			} else if (screenPos.x < 450) {
				horizontal = 'right'
			}
			setTooltipAlign((prev) => ({ ...prev, horizontal }))
			setTooltipPosition({ x: screenPos.x, y: screenPos.y })
		}
		setHoveredPlace(place)
	}

	const handleMarkerLeavePlace = () => {
		setHoveredPlace(null)
	}

	// Gestion du survol pour les markers de type "event"
	const handleMarkerEnterEvent = (eventFeature) => {
		if (mapRef.current) {
			const map = mapRef.current.getMap()
			const [longitude, latitude] = eventFeature.geometry.coordinates
			const screenPos = map.project([longitude, latitude])
			const viewportWidth = window.innerWidth
			let horizontal = 'center'
			if (screenPos.x > viewportWidth - 450) {
				horizontal = 'left'
			} else if (screenPos.x < 450) {
				horizontal = 'right'
			}
			setTooltipAlign((prev) => ({ ...prev, horizontal }))
			setTooltipPosition({ x: screenPos.x, y: screenPos.y })
		}
		setHoveredEvent(eventFeature)
	}

	const handleMarkerLeaveEvent = () => {
		setHoveredEvent(null)
	}

	// Utiliser hoveredPlace ou hoveredEvent pour le tooltip
	const hoveredFeature = hoveredPlace || hoveredEvent

	// Ajustement dynamique de l'alignement vertical du tooltip
	useEffect(() => {
		if (hoveredFeature && tooltipRef.current) {
			const tooltipHeight = tooltipRef.current.offsetHeight
			const margin = 10
			const viewportHeight = window.innerHeight
			let vertical = 'bottom'
			if (tooltipPosition.y + tooltipHeight + margin > viewportHeight) {
				vertical = 'top'
			} else if (tooltipPosition.y - tooltipHeight - margin < 0) {
				vertical = 'bottom'
			} else {
				vertical =
					viewportHeight - tooltipPosition.y > tooltipPosition.y
						? 'bottom'
						: 'top'
			}
			setTooltipAlign((prev) => ({ ...prev, vertical }))
		}
	}, [hoveredFeature, tooltipPosition])

	useEffect(() => {
		if (tooltipRef.current) {
			tooltipRef.current.style.setProperty(
				'--tooltip-left',
				`${tooltipPosition.x}px`
			)
			tooltipRef.current.style.setProperty(
				'--tooltip-top',
				`${tooltipPosition.y}px`
			)
		}
	}, [tooltipPosition])

	return (
		<div className="mapContainer">
			<Map
				ref={mapRef}
				mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
				mapStyle="mapbox://styles/weaselmap/cl0c7riwf000d14tamjgzvqrg"
				logoPosition="top-right"
				addControl="top-left"
				attributionControl={false}
				initialViewState={{
					longitude: mapViewLocation.userLon,
					latitude: mapViewLocation.userLat,
					zoom: 12.5,
					bearing: 0
				}}
				dragRotate={false}
				touchRotate={false}
				minZoom={window.innerWidth < 768 ? 8 : 10}
				maxZoom={17}
				onMoveEnd={() => {
					handleBoundsChange()
					handleZoomChange()
				}}
				onClick={handleClusterClick}
			>
				<AttributionControl
					position={attributionConfig.position}
					compact={attributionConfig.compact}
				/>
				<Source
					id="combined"
					type="geojson"
					data={combinedData}
					cluster={true}
					clusterMaxZoom={13}
					clusterRadius={50}
				>
					{currentZoom < zoomThreshold && (
						<>
							<Layer {...clusterLayerCombined} />
							<Layer {...clusterCountLayerCombined} />
							<Layer {...unclusteredLayerCombined} />
						</>
					)}
				</Source>

				{/* Affichage des marqueurs individuels en mode zoom élevé */}
				{currentZoom >= zoomThreshold &&
					combinedData.features.map((feature) => {
						const [longitude, latitude] =
							feature.geometry.coordinates
						if (feature.properties.type === 'place') {
							const isPOI = feature.properties.pointOfInterest
							return (
								<MarkerMap
									key={feature.id}
									latitude={latitude}
									longitude={longitude}
								>
									<div
										className={`marker ${
											highlightedPlaceId === feature.id
												? 'highlighted-marker'
												: ''
										} ${isPOI ? 'POI' : ''} place-marker`}
										data-title={feature.properties.title}
										onClick={() => {
											setSelectedPlaceId(feature.id)
											setSelectedEventId(null)
											setActiveTab('places')
											setActiveMenu('menu2')
										}}
										onMouseEnter={() =>
											handleMarkerEnterPlace(feature)
										}
										onMouseLeave={handleMarkerLeavePlace}
										role="button"
									>
										<span className="material-symbols-outlined">
											{isPOI ? 'nearby' : 'location_on'}
										</span>
									</div>
								</MarkerMap>
							)
						} else if (feature.properties.type === 'event') {
							return (
								<MarkerMap
									key={feature.id}
									latitude={latitude}
									longitude={longitude}
								>
									<div
										className={`marker ${
											highlightedEventId === feature.id
												? 'highlighted-marker'
												: ''
										} event-marker`}
										data-title={feature.properties.title}
										onClick={() => {
											setSelectedEventId(feature.id)
											setSelectedPlaceId(null)
											setActiveTab('events')
											setActiveMenu('menu2')
										}}
										onMouseEnter={() =>
											handleMarkerEnterEvent(feature)
										}
										onMouseLeave={handleMarkerLeaveEvent}
										role="button"
									>
										<span className="material-symbols-outlined">
											event
										</span>
									</div>
								</MarkerMap>
							)
						}
						return null
					})}
			</Map>

			{(hoveredPlace || hoveredEvent) && window.innerWidth > 768 && (
				<div
					className={`mapCardsContainer tooltip--${tooltipAlign.horizontal} tooltip--${tooltipAlign.vertical}`}
					ref={tooltipRef}
				>
					{currentZoom >= zoomThreshold && (
						<MapCard
							place={hoveredFeature}
							userPreferences={userPreferences}
							onClick={() => {
								if (hoveredPlace) {
									setSelectedPlaceId(hoveredPlace.id)
									setSelectedEventId(null)
									setActiveTab('places')
									setActiveMenu('menu2')
								} else if (hoveredEvent) {
									setSelectedEventId(hoveredEvent.id)
									setSelectedPlaceId(null)
									setActiveTab('events')
									setActiveMenu('menu2')
								}
							}}
						/>
					)}
				</div>
			)}
		</div>
	)
}

export default MapMain
