import React, { useState, useRef, useEffect } from 'react'

///////////////Components///////////////
import MenuOpen from '../Menu_open/MenuOpen'
import PlaceCard from '../../Objects/Place_card'
import SettingsUser from '../../Objects/SettingsUser'
import FavoritesList from '../../Objects/FavoritesList'
import TagsBox from '../../Objects/TagsBox'
import CitySearch from '../../Objects/CitySearch'

///////////////Objets///////////////
import weaselIconBrown from '../../../assets/beletteMarron256.png'
import weaselIconBlue from '../../../assets/beletteBleu256.png'
import ToggleFilters from '../../Objects/Btn-ToggleFilters'
import UserPresentation from '../../Objects/UserPresentation'
import EventCard from '../../Objects/Event_card'

const MenuNav = ({
	data,
	events,
	userId,
	userStatus,
	userEmail,
	userPseudo,
	userLevel,
	isAnonymous,
	userPreferences,
	isEmailVerified,
	resendVerificationEmail,
	onPreferencesUpdate,
	filtersEnabled,
	setFiltersEnabled,
	activeMenu,
	setActiveMenu,
	activeTab,
	setActiveTab,
	selectedPlaceId,
	setSelectedPlaceId,
	selectedEventId,
	setSelectedEventId,
	onCitySearch,
	userLocation,
	handleAdmin,
	handlePro
}) => {
	const [isScrolled, setIsScrolled] = useState(false)
	const [maxMenu, setMaxMenu] = useState(true)
	// const [selectedTab, setSelectedTab] = useState('places')
	const [touchStartY, setTouchStartY] = useState(null)
	const touchThreshold = 100 // Pixels pour détecter le glissement
	const [randomPlaceId, setRandomPlaceId] = useState(null)
	const [randomEventId, setRandomEventId] = useState(null)
	const menu1Ref = useRef(null)
	const menu2Ref = useRef(null)

	const handleMenuClick = (menu) => {
		setActiveMenu((prevMenu) =>
			prevMenu === menu && prevMenu !== 'menuMap' ? null : menu
		)
	}

	const handleMaximizeToggle = () => {
		setMaxMenu((prevMaxMenu) => !prevMaxMenu)
	}

	// Gestionnaires d'événements tactiles pour le geste de glissement vers le bas
	const handleTouchStart = (e) => {
		setTouchStartY(e.touches[0].clientY)
	}

	const handleTouchMove = (e) => {
		if (touchStartY === null) return

		const touchY = e.touches[0].clientY
		const deltaY = touchY - touchStartY

		if (deltaY > touchThreshold) {
			// L'utilisateur a glissé vers le bas
			setActiveMenu(null)
			setMaxMenu(false) // Réduire le menu
			setTouchStartY(null)
			e.preventDefault() // Empêche le rafraîchissement de la page
		} else if (deltaY < -touchThreshold) {
			// L'utilisateur a glissé vers le haut
			setMaxMenu(true) // Maximiser le menu
			setTouchStartY(null)
		}
	}

	const handleTouchEnd = () => {
		setTouchStartY(null)
	}

	// Défilement vers le haut le menu1
	const handleScrollTop = () => {
		if (menu1Ref.current) {
			menu1Ref.current.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		}
	}

	const handleScroll = () => {
		if (menu1Ref.current) {
			// Si le scroll est en haut, le bouton sera caché
			setIsScrolled(menu1Ref.current.scrollTop > 500)
		}
	}

	useEffect(() => {
		if (menu1Ref.current) {
			const menuElement = menu1Ref.current
			menuElement.addEventListener('scroll', handleScroll)
			// Nettoyage lors du démontage du composant
			return () => {
				menuElement.removeEventListener('scroll', handleScroll)
			}
		}
	}, [])

	useEffect(() => {
		if (activeMenu === 'menu2' && menu2Ref.current) {
			// Réinitialiser le scroll du menu 2
			menu2Ref.current.scrollTop = 0
		}
	}, [activeMenu, selectedPlaceId, selectedEventId])

	useEffect(() => {
		if (!selectedPlaceId && data && data.length > 0) {
			const randomIndex = Math.floor(Math.random() * data.length)
			setRandomPlaceId(data[randomIndex].id)
		}
	}, [selectedPlaceId, selectedEventId, data, userLocation])

	useEffect(() => {
		if (!selectedEventId && events && events.length > 0) {
			const randomIndex = Math.floor(Math.random() * events.length)
			setRandomEventId(events[randomIndex].id)
		}
	}, [selectedPlaceId, selectedEventId, events, userLocation])

	return (
		<div
			className={`menuNav ${
				activeMenu && activeMenu !== 'menuMap' ? 'active' : ''
			}`}
		>
			<nav>
				<button
					className={`menuNav__buttonMenu ${
						activeMenu === 'menu1' ? 'button-active' : ''
					}`}
					onClick={() => handleMenuClick('menu1')}
					title="Afficher les lieux"
				>
					<span className="material-symbols-outlined">menu</span>
				</button>

				<button
					className={`menuNav__buttonWeasel ${
						activeMenu === 'menu2' ? 'button-active' : ''
					}`}
					onClick={() => handleMenuClick('menu2')}
					title="Afficher la prochaine sortie"
				>
					<span className="menuNav__buttonWeasel__span">
						<img
							className="menuNav__buttonWeasel__img"
							src={
								activeMenu === 'menu2'
									? weaselIconBlue
									: weaselIconBrown
							}
							alt=""
						/>
					</span>
				</button>

				<button
					className={`menuNav__buttonMap ${
						activeMenu !== 'menuMap' && activeMenu !== null
							? ''
							: 'button-active'
					}`}
					onClick={() => handleMenuClick('menuMap')}
					title="Afficher la carte"
				>
					<span className="material-symbols-outlined">map</span>
				</button>

				{(userStatus === 'pro' || userStatus === 'asso') && (
					<button
						className="menuNav__buttonMenu"
						onClick={handlePro}
						title={
							userStatus === 'pro'
								? 'Page professionelle'
								: 'Page Association'
						}
					>
						<span className="material-symbols-outlined">
							cottage
						</span>
					</button>
				)}

				<button
					className={`menuNav__buttonSettings ${
						activeMenu === 'menu3' ? 'button-active' : ''
					}`}
					onClick={() => handleMenuClick('menu3')}
					title="Afficher les réglages"
				>
					<span className="material-symbols-outlined">settings</span>
				</button>
			</nav>

			{/* Menu 1 */}
			<div
				ref={menu1Ref}
				className={`menuNav__containeMenu ${
					activeMenu === 'menu1' ? 'containeMenu-active' : ''
				} ${maxMenu ? 'maximized' : ''}`}
			>
				<div className="menuNav__containeMenu__containeButton">
					<button
						className="menuNav__containeMenu__button"
						onClick={handleMaximizeToggle}
						onTouchStart={handleTouchStart}
						onTouchMove={handleTouchMove}
						onTouchEnd={handleTouchEnd}
					>
						{/* <span className="material-symbols-outlined">maximize</span> */}
						{maxMenu ? (
							<span className="material-symbols-outlined">
								arrow_drop_down
							</span>
						) : (
							<span className="material-symbols-outlined">
								arrow_drop_up
							</span>
						)}
					</button>
				</div>
				{activeMenu === 'menu1' && (
					<MenuOpen
						title={'Autour de chez vous'}
						variant={'menuOpen-aroundYou'}
					>
						<CitySearch onCitySelect={onCitySearch} />
						<p className="menuOpen__content__message">
							Retrouvez ici des idées de sorties proches de chez
							vous. Cliquez sur l'un des lieux pour le découvrir.
						</p>
						<ToggleFilters
							filtersEnabled={filtersEnabled}
							setFiltersEnabled={setFiltersEnabled} // Passer la fonction pour mettre à jour l'état
							className={'toggleFiltersMenu'}
						/>

						{/* Gestion des onglets */}
						<div className="tabs">
							<button
								className={`tab-button ${
									activeTab === 'places' ? 'active' : ''
								}`}
								onClick={() => setActiveTab('places')}
							>
								Lieux
							</button>

							<button
								className={`tab-button ${
									activeTab === 'events' ? 'active' : ''
								}`}
								onClick={() => setActiveTab('events')}
							>
								Événements
							</button>

							<button
								className={`tab-button ${
									activeTab === 'favorites' ? 'active' : ''
								}`}
								onClick={() => setActiveTab('favorites')}
							>
								Favoris 💖
							</button>
						</div>

						{/* Contenu des onglets */}
						<div className={`tab-content tab-content-${activeTab}`}>
							{activeTab === 'places' && (
								<PlaceCard
									className={'placeCard-aroundYou'}
									data={data}
									isEmailVerified={isEmailVerified}
									resendVerificationEmail={
										resendVerificationEmail
									}
									userStatus={userStatus}
									userId={userId}
									isAnonymous={isAnonymous}
									onSeeMore={setSelectedPlaceId}
									userPreferences={userPreferences}
								/>
							)}

							{activeTab === 'events' && (
								<>
									<EventCard
										className={'eventCard-aroundYou'}
										data={events}
										isEmailVerified={isEmailVerified}
										resendVerificationEmail={
											resendVerificationEmail
										}
										userStatus={userStatus}
										userId={userId}
										isAnonymous={isAnonymous}
										onSeeMore={setSelectedEventId}
										userPreferences={userPreferences}
									/>
								</>
							)}

							{activeTab === 'favorites' && (
								<FavoritesList
									userId={userId}
									userPreferences={userPreferences}
									userStatus={userStatus}
									isEmailVerified={isEmailVerified}
									userEmail={userEmail}
									isAnonymous={isAnonymous}
									setSelectedPlaceId={setSelectedPlaceId}
									onSeeMore={setSelectedPlaceId}
									onSeeMoreEvent={setSelectedEventId}
								/>
							)}
						</div>

						{isScrolled && (
							<button
								className="menuNav__containeMenu__topBtn"
								onClick={handleScrollTop}
								title="Remonter"
							>
								<span className="material-symbols-outlined">
									arrow_upward_alt
								</span>
							</button>
						)}
					</MenuOpen>
				)}
			</div>

			{/* Menu 2 */}
			<div
				ref={menu2Ref}
				className={`menuNav__containeMenu ${
					activeMenu === 'menu2' ? 'containeMenu-active' : ''
				} ${maxMenu ? 'maximized' : ''}`}
			>
				<div className="menuNav__containeMenu__containeButton">
					<button
						className="menuNav__containeMenu__button"
						onClick={handleMaximizeToggle}
						onTouchStart={handleTouchStart}
						onTouchMove={handleTouchMove}
						onTouchEnd={handleTouchEnd}
					>
						{/* <span className="material-symbols-outlined">maximize</span> */}
						{maxMenu ? (
							<span className="material-symbols-outlined">
								arrow_drop_down
							</span>
						) : (
							<span className="material-symbols-outlined">
								arrow_drop_up
							</span>
						)}
					</button>
				</div>
				{activeMenu === 'menu2' && (
					<MenuOpen
						title={'Votre prochaine sortie ?'}
						variant={'menuOpen-nextAdventure'}
						handleMenuClick={handleMenuClick}
					>
						{activeTab === 'places' &&
						(selectedPlaceId || randomPlaceId) ? (
							<PlaceCard
								className={'placeCard-nextOuting'}
								data={data}
								selectedPlaceId={
									selectedPlaceId || randomPlaceId
								}
								userPreferences={userPreferences}
								userStatus={userStatus}
								userEmail={userEmail}
								isEmailVerified={isEmailVerified}
								userId={userId}
								isAnonymous={isAnonymous}
								onSeeOnMap={setSelectedPlaceId}
							/>
						) : activeTab === 'events' &&
						  (selectedEventId || randomEventId) ? (
							<EventCard
								className={'eventCard-nextOuting'}
								data={events}
								selectedPlaceId={
									selectedEventId || randomEventId
								}
								userPreferences={userPreferences}
								userStatus={userStatus}
								userEmail={userEmail}
								isEmailVerified={isEmailVerified}
								userId={userId}
								isAnonymous={isAnonymous}
								// onSeeMore={handleSeeMoreEvent}
								// Vous pouvez adapter la prop onSeeOnMap si nécessaire
								onSeeOnMap={setSelectedEventId}
							/>
						) : (
							<div>
								Selectionnez un lieu ou un événement pour le
								découvrir
							</div>
						)}
					</MenuOpen>
				)}
			</div>

			{/* Menu 3 */}
			<div
				className={`menuNav__containeMenu ${
					activeMenu === 'menu3' ? 'containeMenu-active' : ''
				} ${maxMenu ? 'maximized' : ''}`}
			>
				<div className="menuNav__containeMenu__containeButton">
					<button
						className="menuNav__containeMenu__button"
						onClick={handleMaximizeToggle}
						onTouchStart={handleTouchStart}
						onTouchMove={handleTouchMove}
						onTouchEnd={handleTouchEnd}
					>
						{/* <span className="material-symbols-outlined">maximize</span> */}
						{maxMenu ? (
							<span className="material-symbols-outlined">
								arrow_drop_down
							</span>
						) : (
							<span className="material-symbols-outlined">
								arrow_drop_up
							</span>
						)}
					</button>
				</div>
				{activeMenu === 'menu3' && (
					<MenuOpen
						title={'Profil & Préférences'}
						children={'test3'}
						variant={'menuOpen-settings'}
					>
						<TagsBox
							userId={userId}
							userPreferences={userPreferences}
							onPreferencesUpdate={onPreferencesUpdate} // Passer la fonction de rappel
							isAnonymous={isAnonymous}
						/>

						{!isAnonymous && (
							<UserPresentation
								userPseudo={userPseudo}
								userLevel={userLevel}
							/>
						)}
						<SettingsUser />
					</MenuOpen>
				)}
			</div>
		</div>
	)
}

export default MenuNav
