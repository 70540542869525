import { useState } from 'react'
import PropTypes from 'prop-types'
import { doc, setDoc, collection } from 'firebase/firestore'
import { firestore } from '../../../services/firebaseConfig'

const SignalForm = ({
	placeId,
	commentId,
	userEmail,
	onClose,
	isEmailVerified,
	elementName,
	type
}) => {
	const [message, setMessage] = useState('')
	const [email, setEmail] = useState(userEmail || '') // État local pour l'email
	const [error, setError] = useState('')
	const [success, setSuccess] = useState('')

	// Gestion de l'envoi du formulaire de signalement
	const handleSubmit = async (e) => {
		e.preventDefault()

		if (!message.trim()) {
			setError('Le message de signalement ne peut pas être vide.')
			return
		}

		if (!email.trim()) {
			setError('Veuillez fournir votre adresse email.')
			return
		}

		// Optionnel : Vérifier que l'email est valide
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (!emailRegex.test(email)) {
			setError("L'adresse email n'est pas valide.")
			return
		}

		try {
			const reportData = {
				reportId: placeId || commentId || '', // ID du lieu ou du commentaire signalé
				placeId: placeId || '', // Inclure le placeId
				commentId: commentId || '', // Inclure le commentId
				userEmail: email, // Utiliser l'email de l'état local
				message,
				type,
				timestamp: new Date(), // Timestamp de création du signalement
				elementName: elementName || '' // Inclure le nom de l'élément signalé
			}

			// Ajouter le signalement dans Firestore
			const reportRef = doc(collection(firestore, 'reports'))
			await setDoc(reportRef, reportData)

			setSuccess('Votre signalement a été envoyé avec succès. Merci')
			setMessage('') // Réinitialise le message
			setError('')
			setTimeout(() => {
				// Ferme la fenêtre après envoi du signalement
				onClose()
			}, 2000)
		} catch (err) {
			console.error("Erreur lors de l'envoi du signalement :", err)
			setError("Une erreur est survenue lors de l'envoi du signalement.")
		}
	}

	return (
		<div className="signalForm">
			{type === 'contact' ? (
				<h3>Envoyer un message</h3>
			) : (
				<>
					<h3>Signaler un problème</h3>
					<span>avec {elementName}</span>
				</>
			)}

			<form onSubmit={handleSubmit}>
				<label htmlFor="email">Votre adresse email</label>
				<input
					type="email"
					id="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					disabled={isEmailVerified}
					readOnly={isEmailVerified}
					required
				/>

				{type === 'contact' ? (
					<label htmlFor="message">Votre message</label>
				) : (
					<label htmlFor="message">Description du problème</label>
				)}
				<textarea
					id="message"
					value={message}
					onChange={(e) => {
						if (e.target.value.length <= 2500) {
							setMessage(e.target.value)
						}
					}}
					placeholder={
						type === 'contact'
							? 'Votre message'
							: 'Expliquez la raison de votre signalement'
					}
					required
				/>
				<span
					className={
						message.length >= 2500
							? 'messageLength error'
							: 'messageLength'
					}
				>
					{' '}
					{message.length}/2500 caractères
				</span>

				<button type="submit">
					{type === 'contact'
						? 'Envoyer votre message'
						: 'Envoyer le signalement'}
				</button>
				{error && <p className="error">{error}</p>}
				{success && <p className="success">{success}</p>}
			</form>
		</div>
	)
}

SignalForm.propTypes = {
	placeId: PropTypes.string,
	commentId: PropTypes.string,
	userEmail: PropTypes.string,
	onClose: PropTypes.func,
	isEmailVerified: PropTypes.bool,
	elementName: PropTypes.string,
	type: PropTypes.string
}

export default SignalForm
