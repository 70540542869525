import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

// Component
import AddEventForm from '../../components/Objects/Form-Event'
import FormSignalement from '../../components/Objects/Form-Signalement'
import EventHistory from '../../components/Objects/EventHistory'

import beletteMap from '../../assets/beletteMap.png'

const clearCache = () => {
	const excludeKey = 'changelog_2024-10-31'

	// Stocker la valeur à conserver
	const excludedValue = localStorage.getItem(excludeKey)

	// Supprimer tous les éléments du localStorage
	localStorage.clear()

	// Restaurer l'élément exclu
	if (excludedValue !== null) {
		localStorage.setItem(excludeKey, excludedValue)
	}

	// Supprimer toutes les données du sessionStorage si nécessaire
	sessionStorage.clear()
}

const BoardPro = ({ locationDefault }) => {
	const navigate = useNavigate()
	const [activeTab, setActiveTab] = useState(locationDefault) // Définit l'onglet actif par défaut
	const [menuOpen, setMenuOpen] = useState(false)

	//Récupération des éléments de la page Home
	const location = useLocation()

	const { userId, userPseudo, userEmail, userStatus } = location.state || {}
	useEffect(() => {
		if (!userId) {
			// Redirige vers la page de connexion ou une autre page appropriée
			navigate('/')
		}
	}, [userId, navigate])

	const handleTabChange = (tab) => {
		setActiveTab(tab)
		setMenuOpen(false) // Fermer le menu hamburger après la sélection
	}

	const toggleMenu = () => {
		setMenuOpen((prev) => !prev)
	}

	const handleClick = () => {
		clearCache()
		navigate('/') // Rediriger vers "/"
	}

	// Fermer le menu si un clic est détecté en dehors
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (
				!event.target.closest('.boardPro__nav__dropdown') &&
				!event.target.closest('.boardPro__nav__hamburger')
			) {
				setMenuOpen(false)
			}
		}

		document.addEventListener('click', handleOutsideClick)
		return () => {
			document.removeEventListener('click', handleOutsideClick)
		}
	}, [])

	return (
		<div className="boardPro">
			<nav className="boardPro__nav">
				<div className="boardPro__nav__titleGroupe">
					<button
						title="Retour sur la Map"
						onClick={handleClick}
						className="boardPro__nav__back"
					>
						<span className="material-symbols-outlined">
							arrow_back
						</span>
					</button>
					<div className="boardPro__nav__title">
						<h1 className="boardPro__nav__title">
							Ma page {userStatus}
						</h1>
						<button
							title="Retour sur la Map"
							onClick={handleClick}
							className="boardPro__nav__title--img"
						>
							<img src={beletteMap} alt="Logo WesaelMap" />
						</button>
					</div>
					<button
						className={`boardPro__nav__hamburger ${
							menuOpen ? 'active' : ''
						}`}
						onClick={toggleMenu}
					>
						<span className="material-symbols-outlined">
							{menuOpen === false ? 'menu' : 'close'}
						</span>
					</button>
				</div>

				<div
					className={`boardPro__nav__dropdown ${
						menuOpen ? 'active' : ''
					}`}
				>
					<button
						onClick={() => handleTabChange('accueil')}
						className={`boardPro__nav__dropdown__btn ${
							activeTab === 'accueil' ? 'active' : ''
						}`}
					>
						Accueil
					</button>
					<button
						onClick={() => handleTabChange('addEvent')}
						className={`boardPro__nav__dropdown__btn ${
							activeTab === 'addEvent' ? 'active' : ''
						}`}
					>
						Créer un événement
					</button>
					<button
						onClick={() => handleTabChange('eventHistory')}
						className={`boardPro__nav__dropdown__btn ${
							activeTab === 'eventHistory' ? 'active' : ''
						}`}
					>
						Historique des événements
					</button>
					<button
						onClick={() => handleTabChange('contact')}
						className={`boardPro__nav__dropdown__btn ${
							activeTab === 'contact' ? 'active' : ''
						}`}
					>
						Contact
					</button>
				</div>

				<div className="boardPro__nav__btnGroupe">
					<button
						onClick={() => handleTabChange('accueil')}
						className={`boardPro__nav__btn ${
							activeTab === 'accueil' ? 'active' : ''
						}`}
					>
						Accueil
					</button>
					<button
						onClick={() => handleTabChange('addEvent')}
						className={`boardPro__nav__btn ${
							activeTab === 'addEvent' ? 'active' : ''
						}`}
					>
						Créer un événement
					</button>
					<button
						onClick={() => handleTabChange('eventHistory')}
						className={`boardPro__nav__btn ${
							activeTab === 'eventHistory' ? 'active' : ''
						}`}
					>
						Historique des événements
					</button>
					<button
						onClick={() => handleTabChange('contact')}
						className={`boardPro__nav__btn ${
							activeTab === 'contact' ? 'active' : ''
						}`}
					>
						Contact
					</button>
				</div>
			</nav>

			{/* <img src={beletteMap} alt="Logo du site, une belette marron" /> */}
			<div className="boardPro__main">
				{activeTab === 'accueil' && (
					<div className="boardPro__main__corps">
						<h4>
							Votre page est en cours de contruction, revenez
							bientôt pour découvrir les nouveautés
						</h4>
						<h5>
							WeaselMap est actuellement en développement, durant
							cette période l'emsemble des fonctionnalités sont
							accessibles gratuitement, les prix indiqués sont
							liés aux phases de test. Profitez-en !
						</h5>
					</div>
				)}
				{activeTab === 'addEvent' && (
					<AddEventForm
						userStatus={userStatus}
						userPseudo={userPseudo}
						userEmail={userEmail}
						userId={userId}
						mode={'creation'}
						onTabChange={handleTabChange}
					/>
				)}
				{activeTab === 'eventHistory' && (
					<div>
						<EventHistory
							userId={userId}
							userStatus={userStatus}
							userEmail={userEmail}
						/>
					</div>
				)}
				{activeTab === 'contact' && (
					<FormSignalement userEmail={userEmail} type={'contact'} />
				)}
			</div>
		</div>
	)
}

export default BoardPro
