import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { doc, getDoc } from 'firebase/firestore'
import { firestore } from '../../../services/firebaseConfig'
import PlaceCard from '../Place_card'

const FavoritesList = ({
	userId,
	userPreferences,
	userStatus,
	isEmailVerified,
	userEmail,
	isAnonymous,
	onSeeMore,
	onSeeMoreEvent
}) => {
	const [favorites, setFavorites] = useState([])
	const [places, setPlaces] = useState([])

	// Récupération des favoris de l'utilisateur
	useEffect(() => {
		const fetchFavorites = async () => {
			if (!userId && !isAnonymous) return

			if (isAnonymous) {
				// Favoris stockés localement pour les utilisateurs anonymes
				const storedFavorites =
					JSON.parse(localStorage.getItem('anonymous_favorites')) ||
					[]
				setFavorites(storedFavorites)
			} else {
				// Favoris stockés dans Firestore pour les utilisateurs connectés
				try {
					const userDoc = await getDoc(
						doc(firestore, 'users', userId)
					)
					if (userDoc.exists()) {
						const userFavorites = userDoc.data().favorites || []
						setFavorites(userFavorites)
					}
				} catch (error) {
					console.error(
						'Erreur lors de la récupération des favoris :',
						error
					)
				}
			}
		}

		fetchFavorites()
	}, [userId, isAnonymous])

	// Récupération des détails des lieux favoris
	useEffect(() => {
		const fetchPlaces = async () => {
			if (favorites.length === 0) {
				setPlaces([])
				return
			}

			try {
				const placePromises = favorites.map(async (placeId) => {
					const placeDoc = await getDoc(
						doc(firestore, 'heritage', placeId)
					)
					return placeDoc.exists()
						? { id: placeId, ...placeDoc.data() }
						: null
				})

				const placesData = await Promise.all(placePromises)
				setPlaces(placesData.filter((place) => place !== null))
			} catch (error) {
				console.error(
					'Erreur lors de la récupération des lieux :',
					error
				)
			}
		}

		fetchPlaces()
	}, [favorites])

	return (
		<div className="favoritesList">
			<h4>
				Retrouvez ici tous vos coups de coeur{' '}
				<span
					className={`material-symbols-outlined favoriteBtn-remove`}
				>
					favorite
				</span>
			</h4>
			{places.length > 0 ? (
				places.map((place) => (
					<PlaceCard
						key={place.id} // Clé unique
						className={'placeCard-favoris'}
						data={[place]}
						userPreferences={userPreferences}
						userStatus={userStatus}
						userEmail={userEmail}
						isEmailVerified={isEmailVerified}
						userId={userId}
						isAnonymous={isAnonymous}
						onSeeMore={onSeeMore}
						onSeeMoreEvent={onSeeMoreEvent}
					/>
				))
			) : (
				<p>Aucun favori enregistré.</p>
			)}
		</div>
	)
}

FavoritesList.propTypes = {
	userId: PropTypes.string,
	userPreferences: PropTypes.object,
	userStatus: PropTypes.string,
	isEmailVerified: PropTypes.bool,
	userEmail: PropTypes.string,
	isAnonymous: PropTypes.bool,
	onSeeMore: PropTypes.func
}

export default FavoritesList
