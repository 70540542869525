import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { fr } from 'date-fns/locale'
import { firestore, storage } from '../../../services/firebaseConfig'
import { addDoc, updateDoc, getDoc, doc, collection } from 'firebase/firestore'
import {
	ref,
	uploadBytes,
	getDownloadURL,
	deleteObject
} from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import imageCompression from 'browser-image-compression'

// Import pour Stripe
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../../services/firebaseConfig'

// Components
import MiniMap from '../Map-Mini/MiniMap'
import Modal from '../../Objects/Modal'

// Objets
import tagsList from '../../../data/event.json'
import EventCard from '../Event_card'

registerLocale('fr', fr)

const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_TOKEN

// Tableau des prix
const priceList = {
	0: 0,
	3: 0, // 8.4% de réduction
	7: 13.99, // Tarif de base 2 euros
	14: 24.99, // 10% de réduction
	30: 35.99, // 40% de réduction
	45: 39.99 // 55% de réduction
}
const baseRatePerDay = priceList[7] / 7 // ≈2€/jour

const AddEventForm = ({
	initialLatitude,
	initialLongitude,
	userStatus,
	userEmail,
	userPseudo,
	userId,
	existingData = [],
	eventId,
	mode,
	onRefresh,
	onTabChange,
	onClose
}) => {
	// États de base
	const [title, setTitle] = useState('')
	const [address, setAddress] = useState('')
	const [postalCode, setPostalCode] = useState('')
	const [city, setCity] = useState('')
	const [latitude, setLatitude] = useState(initialLatitude || '')
	const [longitude, setLongitude] = useState(initialLongitude || '')
	const [isAddressChanged, setIsAddressChanged] = useState(false)
	const [createdByFiled, setCreatedByField] = useState(
		existingData?.[0]?.properties?.createdBy || userPseudo
	)
	const [suggestions, setSuggestions] = useState([])
	const [descriptionFR, setDescriptionFR] = useState('')
	const [selectedTags, setSelectedTags] = useState([])
	const [pictures, setPictures] = useState([])
	const [picturePreviews, setPicturePreviews] = useState([])
	const [error, setError] = useState('')
	const [successMessage, setSuccessMessage] = useState('')
	const [existingPictures, setExistingPictures] = useState([])
	const [imagesToDelete, setImagesToDelete] = useState([])
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [eventDuration, setEventDuration] = useState('1')
	const [dailyHours, setDailyHours] = useState([])
	const [displayBeforeStart, setDisplayBeforeStart] = useState('')
	const [initialDisplayBeforeStart, setInitialDisplayBeforeStart] =
		useState(null)
	const [beforeStartDate, setBeforeStartDate] = useState(null)
	const [contactEmail, setContactEmail] = useState(userEmail)
	const [contactPhone, setContactPhone] = useState('')
	const [linkType, setLinkType] = useState('information')
	const [linkURL, setLinkURL] = useState('')
	const [eventPrice, setEventPrice] = useState('')
	const [isFreeEvent, setIsFreeEvent] = useState(false)
	const [useSameTimeForAllDays, setUseSameTimeForAllDays] = useState(false)
	const [maxDisplayBeforeStartOptions, setMaxDisplayBeforeStartOptions] =
		useState([])
	const [totalPaid, setTotalPaid] = useState(null)
	const [initialLoaded, setInitialLoaded] = useState(false)

	// États pour la modal de paiement
	const [showSummaryModal, setShowSummaryModal] = useState(false)
	const [pendingEventData, setPendingEventData] = useState(null)
	const [isPaymentProcessing, setIsPaymentProcessing] = useState(false)
	const [paymentError, setPaymentError] = useState('')
	const [paymentSuccess, setPaymentSuccess] = useState(false)

	const stripe = useStripe()
	const elements = useElements()

	// Chargement des données existantes en mode update (une seule fois)
	useEffect(() => {
		if (
			mode === 'update' &&
			!initialLoaded &&
			existingData &&
			Array.isArray(existingData) &&
			existingData.length === 1
		) {
			const data = existingData[0]
			const properties = data.properties || {}
			const coordinates = data.geometry?.coordinates || [
				initialLongitude,
				initialLatitude
			]

			setTitle(properties.title || '')
			setAddress(properties.address || '')
			setPostalCode(properties.postalCode || '')
			setCity(properties.city || '')

			if (properties.startDate && properties.endDate) {
				const sd = new Date(properties.startDate.seconds * 1000)
				const ed = new Date(properties.endDate.seconds * 1000)
				const duration = Math.ceil((ed - sd) / (1000 * 3600 * 24)) + 1
				setEventDuration(String(duration))
			} else {
				setEventDuration(properties.eventDuration || '1')
			}

			setStartDate(
				properties.startDate
					? new Date(properties.startDate.seconds * 1000)
					: null
			)
			setEndDate(
				properties.endDate
					? new Date(properties.endDate.seconds * 1000)
					: null
			)

			// Pour l'offre d'affichage : charger l'offre initiale (effectiveDisplayDays) si présente
			if (properties.effectiveDisplayDays) {
				const offer = String(properties.effectiveDisplayDays)
				setInitialDisplayBeforeStart(offer)
				setDisplayBeforeStart(offer)
			} else if (
				properties.displayBeforeStartDate &&
				properties.startDate
			) {
				const sd = new Date(properties.startDate.seconds * 1000)
				const ds = new Date(
					properties.displayBeforeStartDate.seconds * 1000
				)
				const diffDays = Math.ceil((sd - ds) / (1000 * 3600 * 24))
				setDisplayBeforeStart(String(diffDays))
				setBeforeStartDate(ds)
			} else {
				setDisplayBeforeStart('')
				setBeforeStartDate(null)
			}

			setDescriptionFR(properties.descriptionFR || '')
			setSelectedTags(properties.tags || [])
			setContactEmail(properties.contactEmail || '')
			setContactPhone(properties.contactPhone || '')

			if (properties.link) {
				setLinkType(properties.link.type || 'information')
				setLinkURL(properties.link.url || '')
			} else {
				setLinkType('information')
				setLinkURL('')
			}

			setEventPrice(properties.eventPrice || '')
			setIsFreeEvent(
				properties.eventPrice && Number(properties.eventPrice) > 0
			)
			setDailyHours(properties.dailyHours || [])

			setLatitude(coordinates[1] || initialLatitude || '')
			setLongitude(coordinates[0] || initialLongitude || '')

			if (properties.pictures && properties.pictures.length > 0) {
				setExistingPictures(properties.pictures)
			}

			// Récupérer le total payé (si stocké dans la BDD)
			if (properties.totalPaid != null) {
				setTotalPaid(Number(properties.totalPaid))
			} else if (properties.effectiveDisplayDays) {
				setTotalPaid(priceList[properties.effectiveDisplayDays] || 0)
			}

			setInitialLoaded(true)
		}
	}, [existingData, initialLoaded, initialLongitude, initialLatitude, mode])

	// Synchronisation des horaires pour tous les jours si l'option est cochée
	useEffect(() => {
		if (useSameTimeForAllDays) {
			const firstDayHours = dailyHours[0] || {
				startTime: '',
				endTime: ''
			}
			const isSynced = dailyHours.every(
				(day) =>
					day?.startTime === firstDayHours.startTime &&
					day?.endTime === firstDayHours.endTime
			)
			if (!isSynced) {
				setDailyHours(
					Array.from({ length: eventDuration }, () => ({
						startTime: firstDayHours.startTime,
						endTime: firstDayHours.endTime
					}))
				)
			}
		}
	}, [useSameTimeForAllDays, eventDuration, dailyHours])

	// Ajuster le tableau des horaires en fonction de la durée
	useEffect(() => {
		if (!startDate) return
		const duration = parseInt(eventDuration, 10)
		setDailyHours((prevHours) => {
			if (mode === 'update') {
				if (prevHours.length === 0) return prevHours
				if (prevHours.length === duration) return prevHours
				if (prevHours.length < duration) {
					const additional = Array.from(
						{ length: duration - prevHours.length },
						() => ({ startTime: '', endTime: '' })
					)
					return [...prevHours, ...additional]
				}
				return prevHours.slice(0, duration)
			} else {
				return Array.from({ length: duration }, () => ({
					startTime: '',
					endTime: ''
				}))
			}
		})
	}, [startDate, eventDuration, mode])

	// Calcul des options pour l'affichage avant le début
	useEffect(() => {
		if (startDate) {
			const today = new Date()
			const timeDiff = startDate.getTime() - today.getTime()
			const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
			const possibleOptions = Object.keys(priceList)
				.map(Number)
				.filter((option) => option <= diffDays)
				.sort((a, b) => a - b)
			if (possibleOptions.length === 0) {
				possibleOptions.push(0)
			}
			setMaxDisplayBeforeStartOptions(possibleOptions)
		}
	}, [startDate])

	// Nettoyer l'erreur après 5 secondes
	useEffect(() => {
		if (error) {
			const timeout = setTimeout(() => setError(''), 5000)
			return () => clearTimeout(timeout)
		}
	}, [error])

	// Recalculer beforeStartDate et endDate en fonction de displayBeforeStart
	useEffect(() => {
		if (startDate && displayBeforeStart) {
			let computedBeforeStartDate = new Date(startDate)
			computedBeforeStartDate.setDate(
				computedBeforeStartDate.getDate() -
					parseInt(displayBeforeStart, 10)
			)
			const today = new Date()
			today.setHours(0, 0, 0, 0)
			if (computedBeforeStartDate < today) {
				computedBeforeStartDate = today
			}
			setBeforeStartDate(computedBeforeStartDate)

			const computedEndDate = new Date(startDate)
			computedEndDate.setDate(
				computedEndDate.getDate() + parseInt(eventDuration, 10) - 1
			)
			setEndDate(computedEndDate)
		}
	}, [startDate, displayBeforeStart, eventDuration])

	// En mode création, définir par défaut l'option la plus élevée
	useEffect(() => {
		if (mode !== 'update' && maxDisplayBeforeStartOptions.length > 0) {
			const largestOption = Math.max(...maxDisplayBeforeStartOptions)
			setDisplayBeforeStart(String(largestOption))
		}
	}, [maxDisplayBeforeStartOptions, mode])

	// En mode update, charger l'offre initiale une seule fois (si non déjà chargée)
	useEffect(() => {
		if (
			mode === 'update' &&
			!initialLoaded &&
			existingData &&
			Array.isArray(existingData) &&
			existingData.length === 1
		) {
			const data = existingData[0]
			const properties = data.properties || {}
			if (properties.effectiveDisplayDays) {
				const offer = String(properties.effectiveDisplayDays)
				setInitialDisplayBeforeStart(offer)
				setDisplayBeforeStart(offer)
			}
			setInitialLoaded(true)
		}
	}, [existingData, initialLoaded, mode])

	// Calcul du montant à payer (complément) en cas de modification
	const finalAmountToPay = useMemo(() => {
		if (!displayBeforeStart) return 0
		const displayNum = parseInt(displayBeforeStart, 10)
		// Si displayBeforeStart est une option standard (ex. 7, 14, etc.)
		if (priceList.hasOwnProperty(displayBeforeStart)) {
			const newPrice = priceList[displayBeforeStart]
			if (mode === 'update' && totalPaid != null) {
				return newPrice > totalPaid ? newPrice - totalPaid : 0
			}
			return newPrice
		} else {
			// Sinon, on est dans le cas "afficher à partir de maintenant"
			// Déterminer la meilleure option de référence parmi les options applicables (excluant 0)
			const applicableOptions = maxDisplayBeforeStartOptions
				.map((o) => parseInt(o, 10))
				.filter((o) => o > 0 && o <= displayNum)
			if (applicableOptions.length > 0) {
				const baseOption = Math.max(...applicableOptions)
				const basePrice = priceList[baseOption]
				const dailyRate = basePrice / baseOption
				const extraPrice = dailyRate * displayNum
				if (mode === 'update' && totalPaid != null) {
					return extraPrice > totalPaid ? extraPrice - totalPaid : 0
				}
				return extraPrice
			}
			return 0
		}
	}, [displayBeforeStart, mode, totalPaid, maxDisplayBeforeStartOptions])

	// Lors du clic sur une carte de prix, en update, ne pas permettre de choisir une offre inférieure à l'offre initiale
	const handlePriceCardClick = (option) => {
		if (mode === 'update' && initialDisplayBeforeStart != null) {
			const chosen = Math.max(
				parseInt(option, 10),
				parseInt(initialDisplayBeforeStart, 10)
			)
			setDisplayBeforeStart(String(chosen))
		} else {
			setDisplayBeforeStart(String(option))
		}
	}

	// Gestion des photos (identique à votre version)
	const handleRemoveExistingPicture = (index) => {
		const urlToRemove = existingPictures[index]
		setImagesToDelete((prev) => [...prev, urlToRemove])
		setExistingPictures((prev) => prev.filter((_, i) => i !== index))
	}
	function clearCache() {
		localStorage.removeItem(eventId)
	}
	const handleTagClick = (tag) => {
		setSelectedTags((prev) =>
			prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
		)
	}
	const handlePictureChange = (e) => {
		const newPictures = Array.from(e.target.files)
		const maxPhotos = 3
		const totalCurrentPhotos = existingPictures.length + pictures.length
		if (
			userStatus !== 'admin' &&
			totalCurrentPhotos + newPictures.length > maxPhotos
		) {
			setError('Vous ne pouvez ajouter que 3 photos maximum.')
			return
		}
		setPictures((prev) => [...prev, ...newPictures])
		setPicturePreviews((prev) => [
			...prev,
			...newPictures.map((file) => URL.createObjectURL(file))
		])
	}
	const handleRemovePicture = (index) => {
		URL.revokeObjectURL(picturePreviews[index])
		setPictures((prev) => prev.filter((_, i) => i !== index))
		setPicturePreviews((prev) => prev.filter((_, i) => i !== index))
	}
	const handleDrop = (e) => {
		e.preventDefault()
		const droppedFiles = Array.from(e.dataTransfer.files)
		const maxPhotos = 3
		const totalCurrentPhotos = existingPictures.length + pictures.length
		if (
			userStatus !== 'admin' &&
			totalCurrentPhotos + droppedFiles.length > maxPhotos
		) {
			setError('Vous ne pouvez ajouter que 3 photos maximum.')
			return
		}
		setPictures((prev) => [...prev, ...droppedFiles])
		setPicturePreviews((prev) => [
			...prev,
			...droppedFiles.map((file) => URL.createObjectURL(file))
		])
	}
	const convertAndUploadImages = async () => {
		const compressedImageUrls = await Promise.all(
			pictures.map(async (picture) => {
				const options = {
					maxSizeMB: 0.75,
					maxWidthOrHeight: 1024,
					useWebWorker: true,
					fileType: 'image/webp',
					quality: 0.8
				}
				try {
					const compressedFile = await imageCompression(
						picture,
						options
					)
					const uniqueName = `${title
						.replace(/\.[^/.]+$/, '')
						.toLowerCase()
						.replace(/[^a-z0-9]/g, '_')}_${uuidv4()}.webp`
					const pictureRef = ref(
						storage,
						`event-pictures/${uniqueName}`
					)
					await uploadBytes(pictureRef, compressedFile)
					return await getDownloadURL(pictureRef)
				} catch (error) {
					console.error(
						"Erreur lors de la compression de l'image :",
						error
					)
					throw error
				}
			})
		)
		return compressedImageUrls
	}

	const handleAddressChange = async (newAddress) => {
		setAddress(newAddress)
		setIsAddressChanged(true)
		if (newAddress.length > 3) {
			try {
				const response = await fetch(
					`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
						newAddress
					)}.json?access_token=${MAPBOX_API_KEY}&autocomplete=true&types=address`
				)
				const data = await response.json()
				if (data.features) {
					setSuggestions(
						data.features.map((feature) => ({
							label: feature.place_name,
							latitude: feature.center[1],
							longitude: feature.center[0],
							postalCode:
								feature.context?.find((c) =>
									c.id.startsWith('postcode')
								)?.text || '',
							city:
								feature.context?.find((c) =>
									c.id.startsWith('place')
								)?.text || ''
						}))
					)
				}
			} catch (error) {
				console.error(
					'Erreur lors de la récupération des suggestions:',
					error
				)
				setSuggestions([])
			}
		} else {
			setSuggestions([])
		}
	}
	const handleSuggestionSelect = (suggestion) => {
		const filteredAddress = suggestion.label
			.split(',')
			.slice(0, 1)
			.join(',')
			.trim()
		setAddress(filteredAddress)
		setPostalCode(suggestion.postalCode)
		setCity(suggestion.city)
		setLatitude(suggestion.latitude)
		setLongitude(suggestion.longitude)
		setSuggestions([])
	}
	const geocodeAddress = async (
		address,
		postalCode,
		city,
		proximity = null
	) => {
		const fullAddress = `${address}, ${postalCode} ${city}`
		const proximityParam = proximity
			? `&proximity=${proximity.longitude},${proximity.latitude}`
			: ''
		try {
			const response = await fetch(
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
					fullAddress
				)}.json?access_token=${MAPBOX_API_KEY}&types=address,place&country=fr${proximityParam}`
			)
			const data = await response.json()
			if (data.features.length > 0) {
				const [lng, lat] = data.features[0].center
				return { latitude: lat, longitude: lng }
			}
			const fallbackResponse = await fetch(
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
					postalCode + ' ' + city
				)}.json?access_token=${MAPBOX_API_KEY}&country=fr${proximityParam}`
			)
			const fallbackData = await fallbackResponse.json()
			if (fallbackData.features.length > 0) {
				const [lng, lat] = fallbackData.features[0].center
				return { latitude: lat, longitude: lng }
			}
			const nominatimResponse = await fetch(
				`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
					fullAddress
				)}`
			)
			const nominatimData = await nominatimResponse.json()
			if (nominatimData.length > 0) {
				const { lat, lon } = nominatimData[0]
				return { latitude: parseFloat(lat), longitude: parseFloat(lon) }
			}
			throw new Error('Aucun résultat trouvé')
		} catch (error) {
			console.error('Erreur de géocodage:', error)
			throw error
		}
	}

	const finalizeEventCreation = async () => {
		console.log(userEmail)
		try {
			const pictureUrls = await convertAndUploadImages()
			const filteredExistingPictures = existingPictures.filter(
				(url) => !imagesToDelete.includes(url)
			)
			const allPictures = [...filteredExistingPictures, ...pictureUrls]
			const updatedEventData = {
				...pendingEventData,
				properties: {
					...pendingEventData.properties,
					pictures: allPictures
				}
			}
			console.log(userEmail)
			if (mode === 'creation') {
				await addDoc(collection(firestore, 'event'), updatedEventData)
				setSuccessMessage("L'événement a été ajouté avec succès !")
			} else if (mode === 'update') {
				const eventRef = doc(firestore, 'event', eventId)
				// Calcul du nouveau totalPaid à partir du supplément
				const newTotalPaid = (totalPaid || 0) + finalAmountToPay
				// On met à jour l'ensemble du document en fusionnant pendingEventData
				await updateDoc(eventRef, {
					...pendingEventData,
					properties: {
						...pendingEventData.properties,
						totalPaid: newTotalPaid
					}
				})
				clearCache(eventId)
				setSuccessMessage("L'événement a été mis à jour avec succès !")
				if (typeof onRefresh === 'function') {
					onRefresh()
				}
			}
			await Promise.all(
				imagesToDelete.map(async (url) => {
					const imageRef = ref(storage, url)
					await deleteObject(imageRef)
				})
			)
			setTimeout(() => {
				setShowSummaryModal(false)
				setSuccessMessage('')
				setError('')
				mode === 'update' ? onClose() : onTabChange('accueil')
			}, 3000)
		} catch (err) {
			console.error(
				"Erreur lors de la finalisation de l'événement :",
				err
			)
			setError(
				"Une erreur est survenue lors de la création de l'événement."
			)
		}
	}

	const handlePayment = async () => {
		setIsPaymentProcessing(true)
		setPaymentError('')
		try {
			const createPaymentIntentFunc = httpsCallable(
				functions,
				'createPaymentIntent'
			)
			const { data } = await createPaymentIntentFunc({
				displayDuration: parseInt(displayBeforeStart, 10)
			})
			const clientSecret = data.clientSecret
			const cardElement = elements.getElement(CardElement)
			const { paymentIntent, error } = await stripe.confirmCardPayment(
				clientSecret,
				{
					payment_method: { card: cardElement }
				}
			)
			if (error) {
				setPaymentError(error.message)
				setIsPaymentProcessing(false)
				return
			}
			if (paymentIntent && paymentIntent.status === 'succeeded') {
				await finalizeEventCreation()
				setPaymentSuccess(true)
			}
			setIsPaymentProcessing(false)
		} catch (err) {
			console.error('Erreur lors du paiement:', err)
			setPaymentError('Une erreur est survenue pendant le paiement.')
			setIsPaymentProcessing(false)
		}
	}

	const areDailyHoursValid = (dailyHours, startDate) => {
		for (let i = 0; i < dailyHours.length; i++) {
			const dayStartTime = dailyHours[i]?.startTime
			const dayEndTime = dailyHours[i]?.endTime
			if (!dayStartTime || !dayEndTime) {
				return `Les horaires pour le jour ${
					i + 1
				} ne sont pas complets.`
			}
			const currentDay = new Date(startDate)
			currentDay.setDate(currentDay.getDate() + i)
			const dayStartDateTime = new Date(
				`${currentDay.toISOString().split('T')[0]}T${dayStartTime}:00`
			)
			const dayEndDateTime = new Date(
				`${currentDay.toISOString().split('T')[0]}T${dayEndTime}:00`
			)
			if (dayEndDateTime <= dayStartDateTime) {
				return `L'heure de fin doit être après l'heure de début pour le jour ${
					i + 1
				}.`
			}
		}
		return null
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		setPendingEventData(null)
		if (
			!title ||
			!address ||
			!postalCode ||
			!city ||
			!descriptionFR ||
			selectedTags.length === 0
		) {
			setError('Veuillez remplir tous les champs requis.')
			setSuccessMessage('')
			return
		}
		const errorMessage = areDailyHoursValid(dailyHours, startDate)
		if (errorMessage) {
			setError(errorMessage)
			return
		}
		const computedEndDate = new Date(startDate)
		computedEndDate.setDate(
			computedEndDate.getDate() + parseInt(eventDuration, 10) - 1
		)
		setEndDate(computedEndDate)
		let computedBeforeStartDate = new Date(startDate)
		computedBeforeStartDate.setDate(
			computedBeforeStartDate.getDate() - parseInt(displayBeforeStart, 10)
		)
		const today = new Date()
		today.setHours(0, 0, 0, 0)
		if (
			Math.ceil(
				(startDate.getTime() - today.getTime()) / (1000 * 3600 * 24)
			) < 3 ||
			computedBeforeStartDate < today
		) {
			computedBeforeStartDate = today
		}
		setBeforeStartDate(computedBeforeStartDate)

		const startMidnight = new Date(startDate)
		startMidnight.setHours(0, 0, 0, 0)
		const beforeMidnight = new Date(computedBeforeStartDate)
		beforeMidnight.setHours(0, 0, 0, 0)
		const effectiveDisplayDays = Math.ceil(
			(startMidnight - beforeMidnight) / (1000 * 3600 * 24)
		)

		try {
			let coords = { latitude, longitude }
			if (!latitude || !longitude) {
				coords = await geocodeAddress(address, postalCode, city)
				setLatitude(coords.latitude)
				setLongitude(coords.longitude)
			}
			const storageKey = 'event-0'
			localStorage.removeItem(storageKey)
			let createdByData = createdByFiled
			if (!createdByData) {
				const userDocRef = doc(firestore, 'users', userId)
				const userDoc = await getDoc(userDocRef)
				if (userDoc.exists()) {
					createdByData =
						userDoc.data().pseudo || 'Utilisateur inconnu'
				} else {
					throw new Error(
						'Utilisateur introuvable pour associer le pseudo.'
					)
				}
			}

			const eventData = {
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: [coords.longitude, coords.latitude]
				},
				properties: {
					title,
					address,
					postalCode,
					city,
					descriptionFR,
					tags: selectedTags,
					pictures: [...existingPictures, ...picturePreviews],
					startDate,
					endDate: computedEndDate,
					dailyHours,
					displayBeforeStartDate: computedBeforeStartDate,
					effectiveDisplayDays,
					createdAt: new Date(),
					createdBy: createdByData,
					userId,
					userEmail,
					contactEmail,
					contactPhone,
					link: { type: linkType, url: linkURL },
					eventPrice: !isFreeEvent ? null : Number(eventPrice),
					totalPaid:
						mode === 'creation'
							? priceList[displayBeforeStart] != null
								? priceList[displayBeforeStart].toFixed(2)
								: finalAmountToPay.toFixed(2)
							: totalPaid.toFixed(2)
				},
				...(mode === 'update' && { update: true })
			}
			Object.keys(eventData).forEach((key) => {
				if (eventData[key] === undefined) {
					delete eventData[key]
				}
			})
			setPendingEventData(eventData)
			setShowSummaryModal(true)
		} catch (err) {
			setError(
				"Une erreur est survenue, n'hésitez pas à contacter l'équipe."
			)
			setSuccessMessage('')
		}
	}

	// Mettre à jour automatiquement les coordonnées si l'adresse change
	useEffect(() => {
		if (
			isAddressChanged &&
			address.length > 3 &&
			postalCode.length > 3 &&
			city.length > 2
		) {
			geocodeAddress(address, postalCode, city)
				.then((coords) => {
					setLatitude(coords.latitude)
					setLongitude(coords.longitude)
				})
				.catch(() => {
					setLatitude('')
					setLongitude('')
				})
		}
	}, [address, postalCode, city, isAddressChanged])

	return (
		<>
			<form className="addEventForm" onSubmit={handleSubmit}>
				<h2>
					{mode === 'update'
						? "Mettre à jour l'événement"
						: 'Créer un événement'}
				</h2>
				<h3>Donnez-nous les détails à partager avec la communauté !</h3>

				{userStatus === 'admin' && (
					<>
						<label htmlFor="createdBy">
							Evénement proposé par :
						</label>
						<input
							type="text"
							id="createdBy"
							value={createdByFiled}
							onChange={(e) => setCreatedByField(e.target.value)}
							required
						/>
					</>
				)}

				<label htmlFor="title">Nom de l'événement</label>
				<input
					type="text"
					id="title"
					value={title}
					onChange={(e) => {
						if (e.target.value.length <= 50) {
							setTitle(e.target.value)
						}
					}}
					required
				/>

				<label htmlFor="address">Lieu de l'événement</label>
				<input
					type="text"
					id="address"
					value={address}
					onChange={(e) => handleAddressChange(e.target.value)}
					required
					autoComplete="off"
				/>

				{userStatus === 'admin' && suggestions.length > 0 && (
					<ul className="address-suggestions">
						{suggestions.map((suggestion, index) => (
							<li
								key={index}
								onClick={() =>
									handleSuggestionSelect(suggestion)
								}
							>
								{suggestion.label}
							</li>
						))}
					</ul>
				)}

				<div className="addEventForm__cityAndPostalCode">
					<div className="addEventForm__postalCode">
						<label htmlFor="postalCode">Code Postal</label>
						<input
							type="text"
							id="postalCode"
							value={postalCode}
							onChange={(e) => setPostalCode(e.target.value)}
							maxLength={5}
							required
							autoComplete="off"
						/>
					</div>
					<div className="addEventForm__city">
						<label htmlFor="city">Ville</label>
						<input
							type="text"
							id="city"
							value={city}
							onChange={(e) => setCity(e.target.value)}
							required
							autoComplete="off"
						/>
					</div>
				</div>

				{userStatus === 'admin' && (
					<div className="addEventForm__ltdlng">
						<div className="addEventForm__ltd">
							<label htmlFor="latitude">Latitude :</label>
							<input
								type="number"
								id="latitude"
								value={latitude}
								onChange={(e) => setLatitude(e.target.value)}
								step="any"
							/>
						</div>
						<div className="addEventForm__lng">
							<label htmlFor="longitude">Longitude :</label>
							<input
								type="number"
								id="longitude"
								value={longitude}
								onChange={(e) => setLongitude(e.target.value)}
								step="any"
							/>
						</div>
					</div>
				)}

				{latitude && (
					<div className="addEventForm__mapPreview">
						<h4>Prévisualisation de la localisation</h4>
						<MiniMap
							latitude={latitude}
							longitude={longitude}
							onPositionChange={(newLatitude, newLongitude) => {
								setLatitude(newLatitude)
								setLongitude(newLongitude)
							}}
						/>
						<p>
							Si nécessaire, ajustez la position en double
							cliquant sur la carte.
						</p>
					</div>
				)}

				<div className="addEventForm__dateAndDuration">
					<div className="addEventForm__date">
						<label htmlFor="startDate">
							Date de début de l'événement
						</label>
						<DatePicker
							id="startDate"
							selected={startDate}
							onChange={(date) => setStartDate(date)}
							dateFormat="dd/MM/yyyy"
							minDate={new Date()}
							locale="fr"
							placeholderText="Choisissez une date"
							required
						/>
					</div>
					<div className="addEventForm__duration">
						<div id="eventDurationLabel">
							<label htmlFor="eventDuration">
								Durée de l'événement :{' '}
							</label>{' '}
							<span>
								{eventDuration}{' '}
								{eventDuration > 1 ? 'jours' : 'jour'}
							</span>
						</div>
						<div className="slider-container">
							<input
								type="range"
								id="eventDuration"
								min="0"
								max="7"
								value={eventDuration}
								onChange={(e) =>
									setEventDuration(
										Math.max(1, e.target.value)
									)
								}
								required
							/>
						</div>
					</div>
				</div>

				<h3>Horaires</h3>
				<div className="addEventForm__dailyHours">
					{eventDuration > 1 && (
						<div className="addEventForm__sameTimeOption">
							<input
								type="checkbox"
								id="sameTimeAllDays"
								checked={useSameTimeForAllDays}
								onChange={(e) =>
									setUseSameTimeForAllDays(e.target.checked)
								}
							/>
							<label htmlFor="sameTimeAllDays">
								Utiliser les mêmes horaires pour tous les jours
							</label>
						</div>
					)}
					<div className="addEventForm__dailyHoursOption">
						{Array.from({ length: eventDuration }, (_, index) => {
							const currentDate = new Date(startDate)
							currentDate.setDate(currentDate.getDate() + index)
							const formattedDate =
								currentDate.toLocaleDateString('fr-FR', {
									weekday: 'long',
									day: 'numeric',
									month: 'long'
								})
							return (
								<div
									key={index}
									className="addEventForm__dailyHours__day"
								>
									<div className="addEventForm__dailyHours__title">
										{eventDuration > 1 && (
											<span>Jour {index + 1} - </span>
										)}
										{formattedDate}
									</div>
									<div className="addEventForm__dailyHours__inputs">
										<div className="addEventForm__startTime">
											<label
												htmlFor={`startTime-${index}`}
											>
												Heure de début
											</label>
											<input
												type="time"
												id={`startTime-${index}`}
												value={
													dailyHours[index]
														?.startTime || ''
												}
												onChange={(e) => {
													const updatedHours = [
														...dailyHours
													]
													if (!updatedHours[index])
														updatedHours[index] = {}
													updatedHours[
														index
													].startTime = e.target.value
													setDailyHours(updatedHours)
												}}
												required
											/>
										</div>
										<div className="addEventForm__endTime">
											<label htmlFor={`endTime-${index}`}>
												Heure de fin
											</label>
											<input
												type="time"
												id={`endTime-${index}`}
												value={
													dailyHours[index]
														?.endTime || ''
												}
												onChange={(e) => {
													const updatedHours = [
														...dailyHours
													]
													if (!updatedHours[index])
														updatedHours[index] = {}
													updatedHours[
														index
													].endTime = e.target.value
													setDailyHours(updatedHours)
												}}
												required
											/>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>

				<label htmlFor="description" id="descriptionLabel">
					<h3>Description de l'événement</h3>
				</label>
				<textarea
					id="description"
					value={descriptionFR}
					onChange={(e) => {
						if (e.target.value.length <= 750) {
							setDescriptionFR(e.target.value)
						}
					}}
					required
				/>
				<span
					className={
						descriptionFR.length >= 750
							? 'messageLength messageError'
							: 'messageLength'
					}
				>
					{descriptionFR.length}/750 caractères
				</span>

				<div className="tagsBox addEventForm__tagsBox">
					<h4>Caractéristiques de l'événement :</h4>
					<div className="tagsBox__list">
						{tagsList.tags.map((tag) => (
							<button
								key={tag}
								type="button"
								className={
									selectedTags.includes(tag) ? 'selected' : ''
								}
								onClick={() => handleTagClick(tag)}
							>
								{tag}
							</button>
						))}
					</div>
				</div>

				<div className="addEventForm__additionalInfo">
					<div className="eventInfo__title">
						<h3>Informations complémentaires</h3>
						<div> (optionnelles)</div>
					</div>
					<div className="eventInfo">
						<label htmlFor="contactEmail">Email de contact :</label>
						<input
							type="email"
							id="contactEmail"
							value={contactEmail}
							onChange={(e) => setContactEmail(e.target.value)}
							placeholder="ex: contact@example.com"
						/>
						<label htmlFor="contactPhone">
							Téléphone de contact :
						</label>
						<input
							type="tel"
							id="contactPhone"
							value={contactPhone}
							onChange={(e) => setContactPhone(e.target.value)}
							placeholder="Numéro"
						/>
						<div className="eventInfo__item externalLink">
							<div>
								<label htmlFor="linkType">Type de lien :</label>
								<select
									id="linkType"
									value={linkType}
									onChange={(e) =>
										setLinkType(e.target.value)
									}
								>
									<option value="information">
										Informations
									</option>
									<option value="site">Site web</option>
									<option value="billetterie">
										Billetterie
									</option>
								</select>
							</div>
							<div>
								<label htmlFor="linkURL">URL :</label>
								<input
									type="url"
									id="linkURL"
									value={linkURL}
									onChange={(e) => setLinkURL(e.target.value)}
									placeholder="ex: https://..."
								/>
							</div>
						</div>
						<div className="eventInfo__item freeEventOption">
							<input
								type="checkbox"
								id="freeEvent"
								checked={isFreeEvent}
								onChange={(e) =>
									setIsFreeEvent(e.target.checked)
								}
							/>
							<label htmlFor="freeEvent" id="freeEventLabel">
								Événement Payant ?
							</label>
							{isFreeEvent && (
								<div>
									<label htmlFor="eventPrice">
										Prix d'accès
									</label>
									<input
										type="number"
										id="eventPrice"
										value={eventPrice}
										onChange={(e) =>
											setEventPrice(e.target.value)
										}
										min="0"
										step="0.01"
										placeholder="prix"
										required={!isFreeEvent ? false : true}
									/>
									€
								</div>
							)}
						</div>
					</div>
				</div>

				<h3>Boostez la visibilité de votre événement</h3>
				<h4>Ajouter des photos pour l'illustrer</h4>
				<div>(max 3 photos)</div>

				<div className="addEventForm__picturesAndPreviews">
					<div
						className="addEventForm__pictures"
						onDrop={handleDrop}
						onDragOver={(e) => e.preventDefault()}
					>
						<input
							type="file"
							id="pictures"
							multiple
							accept="image/*"
							onChange={handlePictureChange}
						/>
						<p>
							Cliquez ici ou faites glisser des images pour les
							ajouter.
						</p>
						<span className="material-symbols-outlined">
							add_circle
						</span>
					</div>
					{picturePreviews.map((preview, index) => (
						<div key={index} className="picturePreview">
							<img src={preview} alt="Prévisualisation" />
							<button
								type="button"
								className="picturePreview__remove"
								onClick={() => handleRemovePicture(index)}
							>
								<span className="material-symbols-outlined">
									cancel
								</span>
							</button>
						</div>
					))}
					<div className="addEventForm__picturePreviews">
						{existingPictures.map((url, index) => (
							<div
								key={`existing-${index}`}
								className="picturePreview"
							>
								<img src={url} alt="Prévisualisation" />
								<button
									type="button"
									className="picturePreview__remove"
									onClick={() =>
										handleRemoveExistingPicture(index)
									}
								>
									<span className="material-symbols-outlined">
										cancel
									</span>
								</button>
							</div>
						))}
					</div>
				</div>

				<div className="addEventForm__selectPrice">
					{displayBeforeStart > 0 ? (
						<div>
							<h4>
								Choisissez la durée de partage avec la
								communauté :
							</h4>
							<p>
								(Bonne nouvelle : l'affichage durant l'événement
								vous est également <span>offert</span>)
							</p>
							<div className="addEventForm__selectPrice__beforeStartDay">
								Votre événement sera partagé pendant{' '}
								<span>{displayBeforeStart} jours</span> (+ durée
								de l'événement) du :{' '}
								<span>
									{beforeStartDate instanceof Date
										? beforeStartDate.toLocaleDateString()
										: ''}
								</span>{' '}
								au{' '}
								<span>
									{endDate
										? endDate.toLocaleDateString()
										: ''}
								</span>
							</div>
						</div>
					) : (
						<h4>Partager votre événement avec la communauté</h4>
					)}

					{maxDisplayBeforeStartOptions.length > 0 ? (
						<div className="priceCards">
							{(mode === 'update'
								? maxDisplayBeforeStartOptions.filter(
										(option) =>
											!initialDisplayBeforeStart ||
											parseInt(option, 10) >=
												parseInt(
													initialDisplayBeforeStart,
													10
												)
								  )
								: maxDisplayBeforeStartOptions
							).map((option) => {
								const optionValue = parseInt(option, 10)
								const newPrice = priceList[optionValue]
								// En mode update, calcul du supplément à payer
								if (
									mode === 'update' &&
									initialDisplayBeforeStart
								) {
									const currentOffer = parseInt(
										initialDisplayBeforeStart,
										10
									)
									const oldPrice =
										priceList[currentOffer] || 0
									if (optionValue === currentOffer) {
										return (
											<div
												key={option}
												className={`priceCard ${
													displayBeforeStart ===
													`${option}`
														? 'selected'
														: ''
												}`}
												onClick={() =>
													handlePriceCardClick(
														option,
														mode,
														initialDisplayBeforeStart,
														setDisplayBeforeStart
													)
												}
											>
												<div className="priceCard__duration">
													{option} jours
												</div>
												<div className="priceCard__price">
													<div className="price">
														Offre en cours
													</div>
												</div>
											</div>
										)
									} else {
										const delta = newPrice - oldPrice
										return (
											<div
												key={option}
												className={`priceCard ${
													displayBeforeStart ===
													`${option}`
														? 'selected'
														: ''
												}`}
												onClick={() =>
													handlePriceCardClick(
														option,
														mode,
														initialDisplayBeforeStart,
														setDisplayBeforeStart
													)
												}
											>
												<div className="priceCard__duration">
													{option} jours
												</div>
												<div className="priceCard__price">
													<div className="price">
														{delta > 0
															? `+ ${delta.toFixed(
																	2
															  )} €`
															: 'Offert'}
													</div>
													<br />
													<div>
														Prix/jour :{' '}
														{(
															newPrice /
															optionValue
														).toFixed(2)}{' '}
														€
													</div>
												</div>
											</div>
										)
									}
								}
								// En mode création : pour l'option 0, n'afficher "Votre événement approche !" que si startDate est dans moins de 3 jours.
								if (mode !== 'update' && optionValue === 0) {
									if (startDate) {
										const today = new Date()
										const deltaDays = Math.ceil(
											(startDate.getTime() -
												today.getTime()) /
												(1000 * 3600 * 24)
										)
										if (deltaDays < 3) {
											return (
												<div
													key={option}
													className={`priceCard ${
														displayBeforeStart ===
														`${option}`
															? 'selected'
															: ''
													} freeOption`}
													onClick={() =>
														handlePriceCardClick(
															option,
															mode,
															null,
															setDisplayBeforeStart
														)
													}
												>
													<div className="priceCard__duration">
														Votre événement approche
														!
													</div>
													<div className="priceCard__price">
														<div className="freeText">
															Affichage Gratuit
														</div>
														<div className="freeTextInfo">
															pendant la durée de
															l'événement
														</div>
													</div>
												</div>
											)
										} else {
											// Si l'événement est dans 3 jours ou plus, ne pas afficher l'option 0
											return null
										}
									}
									return null
								}
								if (newPrice === 0) {
									return (
										<div
											key={option}
											className={`priceCard ${
												displayBeforeStart ===
												`${option}`
													? 'selected'
													: ''
											}`}
											onClick={() =>
												handlePriceCardClick(
													option,
													mode,
													null,
													setDisplayBeforeStart
												)
											}
										>
											<div className="priceCard__duration">
												{option} jours
											</div>
											<div className="priceCard__price">
												<div
													className="price"
													style={{
														textDecoration:
															'line-through'
													}}
												>
													6 €
												</div>
												<br />
												<div>Offert</div>
											</div>
										</div>
									)
								}
								return (
									<div
										key={option}
										className={`priceCard ${
											displayBeforeStart === `${option}`
												? 'selected'
												: ''
										}`}
										onClick={() =>
											handlePriceCardClick(
												option,
												mode,
												null,
												setDisplayBeforeStart
											)
										}
									>
										<div className="priceCard__duration">
											{option} jours
										</div>
										<div className="priceCard__price">
											<div className="price">
												{newPrice}€
											</div>
											<br />
											<div className="price-day">
												Prix/jour :{' '}
												<span>
													{(
														newPrice / optionValue
													).toFixed(2)}{' '}
													€
												</span>
											</div>
										</div>
										{maxDisplayBeforeStartOptions.length >
											1 && (
											<>
												{newPrice / optionValue <
												baseRatePerDay ? (
													<div className="priceCard__discount">
														{(
															(1 -
																newPrice /
																	(baseRatePerDay *
																		optionValue)) *
															100
														).toFixed(1)}
														% de réduction !
													</div>
												) : (
													<div>Tarif de base</div>
												)}
											</>
										)}
									</div>
								)
							})}
							{/* Ajout de la dernière price card si l'événement est dans plus de 7 jours */}
							{startDate &&
								(() => {
									const today = new Date()
									const deltaDays = Math.ceil(
										(startDate.getTime() -
											today.getTime()) /
											(1000 * 3600 * 24)
									)
									if (deltaDays > 7) {
										// Trouver la meilleure option de référence parmi celles qui sont <= deltaDays
										const applicableOptions =
											maxDisplayBeforeStartOptions
												.map((o) => parseInt(o, 10))
												.filter(
													(o) =>
														o > 0 && o <= deltaDays
												)
										if (applicableOptions.length > 0) {
											const baseOption = Math.max(
												...applicableOptions
											)
											const basePrice =
												priceList[baseOption]
											const dailyRate =
												basePrice / baseOption
											const extraPrice =
												dailyRate * deltaDays
											// En mode update, afficher le supplément à payer (finalAmountToPay) ; sinon, le prix total pour deltaDays
											const priceToDisplay =
												mode === 'update'
													? extraPrice - totalPaid
													: extraPrice
											return (
												<div
													key="extraOption"
													className={`priceCard ${
														displayBeforeStart ===
														String(deltaDays)
															? 'selected'
															: ''
													}`}
													onClick={() =>
														handlePriceCardClick(
															String(deltaDays),
															mode,
															null,
															setDisplayBeforeStart
														)
													}
												>
													<div className="priceCard__duration">
														{deltaDays} jours
													</div>
													<div className="priceCard__price">
														<div className="price">
															{mode === 'update'
																? '+'
																: ''}
															{priceToDisplay.toFixed(
																2
															)}
															€
														</div>
														<br />
														<div className="price-day">
															Prix/jour :{' '}
															<span>
																{(
																	(priceToDisplay +
																		totalPaid) /
																	deltaDays
																).toFixed(2)}
																€
															</span>
														</div>
													</div>
													<div className="priceCard__discount">
														<span>
															Soit à partir
															d'aujourdhui
														</span>
													</div>
												</div>
											)
										}
									}
									return null
								})()}
						</div>
					) : (
						<p className="addEventForm__selectPriceMessage">
							Sélectionnez une date de début pour votre événement
							afin de choisir la meilleure option de diffusion.
						</p>
					)}
				</div>

				<div className="addEventForm__commande">
					<button
						className="addEventForm__button"
						type="submit"
						title="Valider"
					>
						{mode === 'update'
							? 'Prévisualiser les modifications'
							: 'Prévisualiser'}
					</button>
					{mode === 'update' && (
						<button
							className="addEventForm__button"
							onClick={onClose}
						>
							Ne pas Modifier
						</button>
					)}
				</div>
			</form>

			{showSummaryModal && (
				<Modal
					isOpen={showSummaryModal}
					onClose={() => setShowSummaryModal(false)}
				>
					<div className="addEventForm__preview">
						<h2>Récapitulatif de votre événement</h2>
						<div className="addEventForm__preview__title">
							<h3>Prévisualisation</h3>
						</div>
						<EventCard
							key={Date.now()}
							className={'eventCard-preview'}
							data={[pendingEventData]}
							isEmailVerified={true}
							userStatus={userStatus}
							userId={userId}
							isAnonymous={false}
						/>
						<div className="addEventForm__preview__corps">
							<div>
								<h3>Résumé</h3>
								<h4>Nom de l'événement : {title}</h4>
							</div>
							<div className="addEventForm__preview__beforeStartDay">
								Votre événement sera partagé à partir du :{' '}
								<span>
									{beforeStartDate.toLocaleDateString()}
								</span>{' '}
								jusqu'au{' '}
								<span>
									{endDate
										? endDate.toLocaleDateString()
										: ''}
								</span>
							</div>
							{displayBeforeStart > 0 && (
								<div className="addEventForm__preview__duration">
									(soit{' '}
									<span>
										{parseInt(displayBeforeStart) +
											parseInt(eventDuration)}{' '}
										jours
									</span>{' '}
									d'affichage !)
								</div>
							)}
							<div className="addEventForm__preview__price">
								{mode === 'update' ? (
									<>
										<h5>
											Vous aviez déjà payé :{' '}
											{totalPaid != null
												? totalPaid.toFixed(2) +
												  ' € TTC'
												: 'N/A'}
										</h5>
										<h4>
											Supplément à payer :{' '}
											{finalAmountToPay > 0
												? finalAmountToPay.toFixed(2) +
												  ' € TTC'
												: 'Aucun supplément'}
										</h4>
									</>
								) : (
									<h4>
										Montant à payer :{' '}
										{finalAmountToPay > 0
											? finalAmountToPay.toFixed(2) +
											  ' € TTC'
											: 'Offert'}
									</h4>
								)}
							</div>
							{error && <div className="error">{error}</div>}
							{successMessage && (
								<div className="success">{successMessage}</div>
							)}

							<div className="addEventForm__preview__button">
								<button onClick={finalizeEventCreation}>
									Valider
								</button>
								<button
									onClick={() => setShowSummaryModal(false)}
								>
									Retour
								</button>
							</div>
							<div className="addEventForm__preview__message">
								*TVA non récupérable
							</div>
						</div>
					</div>
				</Modal>
			)}
		</>
	)
}

AddEventForm.propTypes = {
	initialLatitude: PropTypes.number,
	initialLongitude: PropTypes.number,
	userStatus: PropTypes.string
}

export default AddEventForm
