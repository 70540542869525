import { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { auth } from '../../services/firebaseConfig'
import { signInAnonymously } from 'firebase/auth'

//Components
import ReportButton from '../../components/Objects/Btn-Report'

///////////////Objets///////////////
import belette from '../../assets/beletteMarron256.png'
// import mapIcon from '../../assets/'

const AuthChoice = () => {
	const navigate = useNavigate()

	// Redirection si l'utilisateur est déjà connecté
	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user && !user.isAnonymous) {
				// Si l'utilisateur est connecté et pas en mode anonyme, on le redirige
				navigate('/')
			}
		})
		return () => unsubscribe() // Nettoyer l'écouteur lorsque le composant est démonté
	}, [navigate])

	// Connexion anonyme
	const handleAnonymousLogin = async () => {
		try {
			await signInAnonymously(auth)
			navigate('/') // Redirection après la connexion anonyme
		} catch (error) {
			console.error('Erreur lors de la connexion anonyme:', error)
		}
	}

	return (
		<div className="authChoice__main">
			<div className="authChoice__main__body">
				<div className="authChoice__main__info">
					<div className="authChoice__card card1">
						<h4>WeaselMap</h4>
						<img src={belette} alt="Tête de belette" />
						<p className="card-title">
							Construisons une réponse à la question
						</p>
						<p className="card-text">
							<span>"On fait quoi aujourd'hui ?"</span>
						</p>
						<span className="material-symbols-outlined">
							location_on
						</span>
					</div>
					<div className="authChoice__card card2">
						<p>Partagez vos lieux préférés</p>
						<span className="material-symbols-outlined">
							add_location_alt
						</span>
					</div>
					<div className="authChoice__card card3">
						<p>Redécouvrez notre patrimoine</p>
					</div>
				</div>
				<div className="authChoice">
					<div className="authCoice__menu">
						<h1>WeaselMap</h1>
						<p className="authCoice__menu__maxime">
							Partageons le patrimoine,
							<br />
							redécouvrez votre région.
						</p>
						<nav className="authCoice__menu">
							<button
								className="authChoice__button"
								onClick={() => navigate('/login')}
							>
								<img src={belette} alt="tête de belette" />
								Connexion par email
							</button>
							<button
								className="authChoice__button"
								onClick={() => navigate('/signup')}
							>
								Créer un compte
							</button>
							{/* <button
								className="authChoice__buttonNoLog"
								onClick={handleAnonymousLogin}
							>
								Utiliser sans se connecter
							</button> */}
						</nav>
					</div>
				</div>
			</div>
			<footer className="authChoice__main__footer">
				<Link to={'/WeaselMap-CGU'}>
					Conditions générales d'utilisation
				</Link>
				<ReportButton type={'contact'} className={'-contact'} />
			</footer>
		</div>
	)
}

export default AuthChoice
