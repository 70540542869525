import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import Carrousel from '../Carrousel'
import ReportButton from '../Btn-Report/BtnReport'
import AddToFavoritesButton from '../Btn-Favoris'
import CreateAccountButton from '../Btn-CreateAccount'
import Modal from '../Modal'
import AddEventForm from '../Form-Event/AddEventForm'

// Objets
import beletteMap from '../../../assets/beletteMap.png'

const EventCard = ({
	className,
	data,
	selectedPlaceId,
	userPreferences = {},
	userStatus,
	isEmailVerified,
	userId,
	userEmail,
	isAnonymous,
	onRefresh,
	onSeeMore,
	onSeeOnMap
}) => {
	const [isModalMajFormOpen, setIsModalMajFormOpen] = useState(false)
	const closeModalMajForm = () => setIsModalMajFormOpen(false)
	const openModalMajForm = () => {
		console.log(userEmail)
		setIsModalMajFormOpen(true)
	}

	const [selectedTags, setSelectedTags] = useState(
		userPreferences.preferences || [] // Défaut à un tableau vide
	)

	const [copyMessage, setCopyMessage] = useState(false)

	// useEffect(() => {
	// 	// Mettre à jour les préférences lorsque props userPreferences change, avec valeurs par défaut
	// 	setSelectedTags(userPreferences.preferences || [])
	// }, [userPreferences])

	const formatDate = (dateInput) => {
		if (!dateInput) return ''

		let date
		// Vérifier si c'est un timestamp Firebase
		if (typeof dateInput === 'object' && dateInput.seconds) {
			date = new Date(dateInput.seconds * 1000)
		} else {
			date = new Date(dateInput)
		}

		if (isNaN(date.getTime())) return '' // si la date est invalide
		const day = date.getDate().toString().padStart(2, '0')
		const month = (date.getMonth() + 1).toString().padStart(2, '0')
		const year = date.getFullYear()
		return `${day}/${month}/${year}`
	}

	// Filtrer les données si un ID spécifique est fourni
	const filteredData = selectedPlaceId
		? data.filter((element) => element.id === selectedPlaceId)
		: data

	const copyToClipboard = (properties) => {
		console.log(filteredData)
		const fullAddress = `${properties.address} ${properties.postalCode} ${properties.city}`
		navigator.clipboard
			.writeText(fullAddress)
			.then(() => {
				setCopyMessage(true)
				setTimeout(() => setCopyMessage(false), 2000) // Message disparaît après 1 seconde
			})
			.catch(() => {
				console.error("Erreur lors de la copie de l'adresse.")
			})
	}

	// **Vérifier si les données sont vides**
	if (filteredData.length === 0 && className === 'eventCard-aroundYou') {
		return (
			<div className={className}>
				<p>Aucun événement à proximité.</p>
				<p>
					Vous êtes <span>un professionnel ou une association</span> ?
					Partagez vos événements avec la communauté ?
				</p>
				{isAnonymous && (
					<>
						<p>
							Pour ajouter des événements connectez vous ou créez
							un compte Pro et devenez{' '}
							<span>partenaire WeaselMap !</span>
						</p>
						<CreateAccountButton />
					</>
				)}
				{!isAnonymous && !isEmailVerified && (
					<p>
						Pour ajouter des événements, vous pouvez transformer
						votre compte actuel en compte Pro et devenenir{' '}
						<span>partenaire WeaselMap !</span>
					</p>
				)}
			</div>
		)
	}

	return (
		<>
			{filteredData.map((element, index) => {
				const idElement = element.id || `event-${index}`

				// const { coordinates } = element.geometry
				const properties = element.properties
				const hasPictures =
					properties.pictures &&
					Object.keys(properties.pictures).length > 0

				// Trier les tags en plaçant les préférés de l'utilisateur en premier
				const sortedTags = properties.tags
					.slice() // Copier le tableau pour éviter de modifier les données d'origine
					.sort((a, b) => {
						const aIsPreferred = selectedTags?.includes(a)
						const bIsPreferred = selectedTags?.includes(b)
						return aIsPreferred === bIsPreferred
							? 0
							: aIsPreferred
							? -1
							: 1
					})

				// Déterminer si on affiche tous les tags ou seulement 3
				const tagsToDisplay =
					className === 'eventCard-nextOuting' ||
					className === 'eventCard-preview'
						? sortedTags
						: sortedTags.slice(0, 3)

				// Générer les tags avec condition
				const tags =
					tagsToDisplay && tagsToDisplay.length > 0 ? (
						tagsToDisplay.map((tag, index) => (
							<span
								key={index}
								className={`eventCard__tag ${
									selectedTags?.includes(tag)
										? 'selected'
										: ''
								}`}
							>
								{tag}
							</span>
						))
					) : (
						<span>Aucun tag disponible</span>
					)

				//Formate les dates pour affichage
				const formattedStartDate = formatDate(properties.startDate)
				const formattedEndDate = formatDate(properties.endDate)

				return (
					<div key={idElement} className={className}>
						{(className === 'eventCard-aroundYou' ||
							className === 'eventCard-history') && (
							<>
								<div
									role="button"
									className="eventCard-aroundYou__button"
									onClick={
										className === 'eventCard-history'
											? openModalMajForm
											: () => onSeeMore(element.id)
									}
								>
									<div className="eventCard__noteAndPosition">
										<AddToFavoritesButton
											placeId={element.id}
											userId={userId}
											isAnonymous={isAnonymous}
											variant={'aroundYou'}
										/>
									</div>

									<h3 className="eventCard-aroundYou__title">
										{properties.title}
									</h3>
									<div className="eventCard__tags">
										{tags}
									</div>
									{hasPictures ? (
										<Carrousel
											title={properties.title}
											pictures={properties.pictures}
											id={idElement}
											reduceView={true}
										/>
									) : (
										<div className="eventCard__no-images">
											Pas encore de photo pour ce lieu.
										</div>
									)}
								</div>

								<Modal
									isOpen={isModalMajFormOpen}
									onClose={closeModalMajForm}
									className={'addPlaceForm__modal'}
								>
									<AddEventForm
										onClose={closeModalMajForm}
										onRefresh={onRefresh}
										userStatus={userStatus}
										userEmail={userEmail}
										userId={userId}
										existingData={filteredData}
										eventId={element.id}
										mode={'update'}
									/>
								</Modal>
							</>
						)}

						{(className === 'eventCard-nextOuting' ||
							className === 'eventCard-preview') && (
							<>
								{/* N'est pas affiché dans la preview */}
								{className === 'eventCard-nextOuting' && (
									<div className="eventCard__noteAndPosition">
										{/* <AddToFavoritesButton
											eventId={selectedPlaceId}
											userId={userId}
											isAnonymous={isAnonymous}
										/> */}
										{/* <span className="eventCard__position">
										XXkm
									</span> */}
									</div>
								)}

								<div className={`${className}__title`}>
									<h3>{properties.title}</h3>
									{className === 'eventCard-nextOuting' && (
										<button
											className="eventCard__seeOnMapButton"
											onClick={() => {
												onSeeOnMap(selectedPlaceId)
											}}
											title="Voir sur la carte"
										>
											<span className="material-symbols-outlined">
												travel_explore
											</span>
										</button>
									)}
								</div>
								<div className="eventCard__tags">{tags}</div>
								{/* N'est pas affiché dans la preview */}
								{className === 'eventCard-nextOuting' && (
									<ReportButton
										placeId={element.id}
										elementName={properties.title}
										userEmail={userEmail || ''}
										isEmailVerified={isEmailVerified}
										userStatus={userStatus}
										type={'place'}
										className={''}
									/>
								)}
								{hasPictures ? (
									<>
										<Carrousel
											title={properties.title}
											pictures={properties.pictures}
											id={idElement}
											reduceView={false}
											preview={true}
										/>
									</>
								) : (
									<div className="eventCard__no-images">
										Pas encore de photo pour ce lieu.
									</div>
								)}
								<div className="eventCard__infos">
									{properties.createdBy && (
										<div id="created">
											Evénement proposé par :{' '}
											<span>{properties.createdBy}</span>
										</div>
									)}
									<h4>Informations : </h4>
									<div className="eventCard__infos__datesAndHours">
										{properties.dailyHours.length > 1 ? (
											<>
												<div>
													Rendez-vous du :<br />
													<span>
														{formattedStartDate}
													</span>{' '}
													au{' '}
													<span>
														{formattedEndDate}
													</span>
												</div>
											</>
										) : (
											<div>
												Rendez-vous le{' '}
												{formattedStartDate}{' '}
											</div>
										)}

										{properties.eventPrice > 0 && (
											<div className="eventCard__infos__datesAndHours__price">
												Prix d'entrée : <br />
												{properties.eventPrice.toFixed(
													2
												)}{' '}
												€
											</div>
										)}

										{/* {properties.eventPrice === 0 && (
											<div className="eventCard__infos__datesAndHours__price">
												Gratuit
											</div>
										)} */}
									</div>
									<div className="eventCard__infos__dailyHours">
										<span>Horaires :</span>
										<ul>
											{properties.dailyHours.map(
												(day, index) => (
													<li key={index}>
														{properties.dailyHours
															.length > 1 &&
															`Jour ${
																index + 1
															} : `}
														{day.startTime} -{' '}
														{day.endTime}
													</li>
												)
											)}
										</ul>
									</div>
									<div className="eventCard__info__adresse">
										<span className="adresse-title">
											Adresse :{' '}
										</span>
										<span>
											{properties.address}{' '}
											{properties.postalCode}{' '}
											{properties.city}
										</span>
										<button
											className="eventCard__copyBtn"
											onClick={() =>
												copyToClipboard(properties)
											}
											title="Copier l'adresse"
										>
											<span className="material-symbols-outlined">
												content_copy
											</span>
										</button>
										{copyMessage && (
											<span className="eventCard__copy-message">
												Adresse copiée !
											</span>
										)}
									</div>
								</div>

								<div className="eventCard__description">
									<h4 className="eventCard__description__title">
										Description :
									</h4>

									<div className="eventCard__description__corps">
										{properties.descriptionFR}
									</div>
									<div className="eventCard__description__infoContact">
										{properties.contactEmail && (
											<div className="eventCard__description__infoContact__item">
												<span>Mail :</span>{' '}
												{properties.contactEmail}
											</div>
										)}
										{properties.contactPhone && (
											<div className="eventCard__description__infoContact__item">
												<span>Téléphone :</span>{' '}
												{properties.contactPhone.replace(
													/(\d{2})(?=\d)/g,
													'$1 '
												)}
											</div>
										)}
										{properties.link.url && (
											<div className="eventCard__description__infoContact__item">
												<span>
													{properties.link.type ===
													'information'
														? "Plus d'informations"
														: properties.link
																.type ===
														  'billetterie'
														? 'Billetterie'
														: properties.link
																.type === 'site'
														? 'Site officiel'
														: ''}{' '}
												</span>
												:{' '}
												<a
													href={properties.link.url}
													target="_blank"
													rel="noopener noreferrer"
												>
													{properties.link.url}
												</a>
											</div>
										)}
									</div>
								</div>
							</>
						)}

						{className === 'eventCard-favoris' && (
							<div
								role="button"
								className="eventCard-aroundYou__button"
								onClick={() => onSeeMore(element.id)}
							>
								<h3 id="favoris__title">
									{properties.title} <br />
									<span>
										{properties.postalCode}{' '}
										{properties.city}
									</span>
								</h3>{' '}
								<div className="eventCard__tags">{tags}</div>
								{hasPictures ? (
									<Carrousel
										title={properties.title}
										pictures={properties.pictures}
										id={idElement}
										reduceView={true}
									/>
								) : (
									<div className="eventCard__no-images">
										Pas encore de photo pour ce lieu.
									</div>
								)}
							</div>
						)}
					</div>
				)
			})}
			{className === 'eventCard-aroundYou' && (
				<footer className={`${className} footer`}>
					<div className="footer__message">
						<img src={beletteMap} alt="Belette avec une carte" />
						<p>Vous avez vu tous les événements proposés ici.</p>
					</div>

					<p>
						Vous êtes{' '}
						<span>un professionnel ou une association</span> ?
						Partagez vos événements avec la communauté ?
					</p>
					{isAnonymous && (
						<>
							<p>
								Pour ajouter des événements connectez vous ou
								créez un compte Pro et devenez{' '}
								<span>partenaire WeaselMap !</span>
							</p>
							<CreateAccountButton />
						</>
					)}
					{!isAnonymous && !isEmailVerified && (
						<p>
							Pour ajouter des événements, vous pouvez transformer
							votre compte actuel en compte Pro et devenenir{' '}
							<span>partenaire WeaselMap !</span>
						</p>
					)}
				</footer>
			)}
		</>
	)
}

// EventCard.propTypes = {
// 	className: PropTypes.string,
// 	data: PropTypes.array.isRequired,
// 	selectedPlaceId: PropTypes.string,
// 	onSeeMore: PropTypes.func,
// 	onSeeOnMap: PropTypes.func,
// 	userPreferences: PropTypes.object
// }

export default EventCard
