import { useState } from 'react'
import PropTypes from 'prop-types'
import {
	doc,
	deleteDoc,
	updateDoc,
	getDocs,
	getDoc,
	collection,
	query,
	where
} from 'firebase/firestore'
import { firestore } from '../../../services/firebaseConfig'
import { ref, deleteObject } from 'firebase/storage' // Importer deleteObject et ref
import { storage } from '../../../services/firebaseConfig' // Importez également le stockage Firebase

import Modal from '../Modal'
import SignalForm from '../Form-Signalement'

//Permet de reporter un commentaire ou une erreur sur un lieu. En mode Admin permet de supprimer un lieu ou un commentaire
const ReportButton = ({
	placeId,
	commentId,
	userEmail,
	elementName,
	isEmailVerified,
	userStatus,
	type,
	className
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const updateAverageRating = async (placeId) => {
		const ratingsSnapshot = await getDocs(
			query(
				collection(firestore, 'ratings'),
				where('placeId', '==', placeId)
			)
		)

		let totalRatings = 0
		let ratingsSum = 0

		ratingsSnapshot.forEach((doc) => {
			ratingsSum += doc.data().rating
			totalRatings++
		})

		const averageRating = totalRatings > 0 ? ratingsSum / totalRatings : 0

		await updateDoc(doc(firestore, `heritage/${placeId}`), {
			'rating.average': averageRating,
			'rating.ratingsCount': totalRatings
		})
	}

	const handleClick = async () => {
		if (userStatus === 'admin' && type !== 'contact') {
			const confirmDelete = window.confirm(
				'Êtes-vous sûr de vouloir supprimer cet élément ?'
			)
			if (confirmDelete) {
				try {
					if (commentId) {
						// Supprimer le commentaire
						await deleteDoc(doc(firestore, 'ratings', commentId))
						updateAverageRating(placeId)
					} else if (placeId) {
						// Récupérer le lieu pour obtenir les URLs des images
						const placeDoc = await getDoc(
							doc(firestore, 'heritage', placeId)
						)
						if (placeDoc.exists()) {
							const placeData = placeDoc.data()
							const pictures = placeData.properties.pictures || []

							// Supprimer les commentaires associés au lieu
							const ratingsQuery = query(
								collection(firestore, 'ratings'),
								where('placeId', '==', placeId)
							)
							const ratingsSnapshot = await getDocs(ratingsQuery)
							const deleteRatingsPromises =
								ratingsSnapshot.docs.map((ratingDoc) => {
									return deleteDoc(ratingDoc.ref)
								})
							await Promise.all(deleteRatingsPromises)

							// Supprimer chaque image du stockage Firebase
							const deletePromises = pictures.map((url) => {
								const imageRef = ref(storage, url)
								return deleteObject(imageRef)
							})
							await Promise.all(deletePromises)

							// Supprimer le lieu dans Firestore
							await deleteDoc(doc(firestore, 'heritage', placeId))

							// Nettoyer le cache après suppression
							localStorage.removeItem(placeId)
							sessionStorage.removeItem(placeId)
						}
					}
					// Recharger la page après toutes les opérations
					window.location.reload()
				} catch (error) {
					console.error('Erreur lors de la suppression :', error)
				}
			}
		} else {
			setIsModalOpen(true)
		}
	}

	return (
		<>
			{className === '-bug' ? (
				<>
					<button
						type="button"
						onClick={handleClick}
						className="reportButton"
						title="Signaler un bug"
					>
						<span className="buttonText">Signaler un bug</span>
						<span className="material-symbols-outlined">
							warning
						</span>
					</button>
				</>
			) : (
				<>
					{/* Bouton d'icône pour signaler */}
					<div className={`signalButton${className}`}>
						<button
							type="button"
							onClick={handleClick}
							className="reportButton"
							title="contactez-nous"
						>
							{type === 'contact' ? (
								<>
									<span className="buttonText">
										contactez-nous
									</span>

									<span className="material-symbols-outlined">
										outgoing_mail
									</span>
								</>
							) : (
								<>
									<span className="buttonText">
										{userStatus === 'admin'
											? 'Supprimer'
											: 'Signaler'}
									</span>
									<span className="material-symbols-outlined">
										{userStatus === 'admin'
											? 'delete'
											: 'report'}
									</span>
								</>
							)}
						</button>
					</div>
				</>
			)}
			{isModalOpen && (
				<Modal
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
				>
					<SignalForm
						isEmailVerified={isEmailVerified}
						placeId={placeId}
						commentId={commentId}
						userEmail={userEmail}
						elementName={elementName}
						type={type}
						onClose={() => setIsModalOpen(false)}
					/>
				</Modal>
			)}
		</>
	)
}

ReportButton.propTypes = {
	placeId: PropTypes.string,
	commentId: PropTypes.string,
	userEmail: PropTypes.string
}

export default ReportButton
