// Importations des modules et composants nécessaires
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { firestore, auth } from '../../services/firebaseConfig'
import {
	query,
	where,
	collection,
	getDocs,
	doc,
	getDoc,
	updateDoc,
	arrayUnion
} from 'firebase/firestore'
import { sendEmailVerification } from 'firebase/auth'
import { geocodeCity, getUserLocation } from '../../services/geolocation' // Service pour géocoder et obtenir la position

// Importation des composants personnalisés
import MenuNav from '../../components/Menus/Menu_nav'
import MapMain from '../../components/Objects/Map-Main'
import Modal from '../../components/Objects/Modal'
import AddPlaceForm from '../../components/Objects/Form-AddPlace/AddPlaceForm'
import Popup from '../../components/Objects/Popup'
import Loading from '../../components/Objects/Loading'
import TagsBox from '../../components/Objects/TagsBox'
import MapControls from '../../components/Objects/Btn-MapControls'

const Home = () => {
	// États pour gérer les données et l'état de l'application
	const [allHeritageItems, setAllHeritageItems] = useState([]) // Toutes les données du patrimoine
	const [heritageItems, setHeritageItems] = useState([]) // Données du patrimoine filtrées
	const [allEvents, setAllEvents] = useState([]) // Tous les événements récupérés
	const [displayEvents, setDisplayEvents] = useState([]) // Événements filtrés et triés
	const [userPreferences, setUserPreferences] = useState([]) // Préférences de l'utilisateur (tags, ville, rayon)
	const [userId, setUserId] = useState(null) // ID de l'utilisateur
	const [filtersEnabled, setFiltersEnabled] = useState(false) // Indique si les filtres sont activés
	const [highlightedPlaceId, setHighlightedPlaceId] = useState(null) // ID du lieu mis en avant
	const [selectedPlaceId, setSelectedPlaceId] = useState(null) // ID du lieu sélectionné
	const [highlightedEventId, setHighlightedEventId] = useState(null) // ID de l'événement mis en avant
	const [selectedEventId, setSelectedEventId] = useState(null) // ID de l'événement sélectionné
	const [isAnonymous, setIsAnonymous] = useState(false) // Indique si l'utilisateur est anonyme
	const [userStatus, setUserStatus] = useState('') // Statut de l'utilisateur (admin, utilisateur, etc.)
	const [userLevel, setUserLevel] = useState('') // Niveau de l'utilisateur
	const [userPseudo, setUserPseudo] = useState('') // Statut de l'utilisateur (admin, utilisateur, etc.)
	const [userLocation, setUserLocation] = useState(null) // Position de l'utilisateur
	const [centerOnPlaceTrigger, setCenterOnPlaceTrigger] = useState(0) // Déclencheur pour recentrer sur un lieu
	const [loadingLocation, setLoadingLocation] = useState(true) // Suivi du chargement de la localisation
	const [geoLocation, setGeoLocation] = useState(true) // Vérifie si le GPS est utilisable
	const [mapBounds, setMapBounds] = useState(null) // Limites actuelles de la carte pour la recherche dans la zone
	const [mapCenter, setMapCenter] = useState(null) // Centre actuel de la carte
	const [userEmail, setUserEmail] = useState('') // Email de l'utilisateur
	const [activeMenu, setActiveMenu] = useState('menu1') // Menu actif dans l'application
	const [activeTab, setActiveTab] = useState('places') // Valeur par défaut, par exemple
	const [isModalOpen, setIsModalOpen] = useState(false) // État du modal d'ajout de lieu
	const [isEmailVerified, setIsEmailVerified] = useState(false) // Indique si l'email est vérifié
	const [showEmailVerificationPopup, setShowEmailVerificationPopup] =
		useState(false) // Affiche la popup de vérification d'email
	const [showChangelogPopup, setShowChangelogPopup] = useState(false) // Affiche la popup du changelog
	const [showPreferencesPopup, setShowPreferencesPopup] = useState(false) // Affiche la popup des préférences
	const navigate = useNavigate() // Hook pour la navigation

	// Constantes du changelog
	// Bien changer le numéro de changeLog dans les fonctions clearCache des desboard Admin et Pro
	const changelogVersion = '2024-10-31' // Version actuelle du changelog
	const changelogExpireDate = useMemo(() => new Date('2024-12-30'), []) // Date d'expiration du changelog

	// Fonctions pour ouvrir et fermer le modal d'ajout de lieu
	const openModal = () => setIsModalOpen(true)
	const closeModal = () => setIsModalOpen(false)

	// Vérifier si le changelog a été lu
	useEffect(() => {
		const checkChangelogReadStatus = async () => {
			// Vérifier dans le localStorage
			const hasReadInLocalStorage = localStorage.getItem(
				`changelog_${changelogVersion}`
			)
			if (hasReadInLocalStorage) {
				setShowChangelogPopup(false)
				return
			}

			// Vérifier dans la base de données
			try {
				const userDocRef = doc(firestore, 'users', userId)
				const userDoc = await getDoc(userDocRef)

				if (userDoc.exists()) {
					const changelogsRead = userDoc.data()?.changelogsRead || []
					if (changelogsRead.includes(changelogVersion)) {
						setShowChangelogPopup(false)
						localStorage.setItem(
							`changelog_${changelogVersion}`,
							'true'
						) // Met à jour le localStorage
						return
					}
				}
			} catch (error) {
				console.error(
					'Erreur lors de la vérification du changelog :',
					error
				)
			}

			// Si aucune des conditions précédentes n'est remplie
			setShowChangelogPopup(new Date() < changelogExpireDate)
		}

		checkChangelogReadStatus()
	}, [changelogVersion, changelogExpireDate, userId])

	// Enregistrer que le changelog a été lu
	const handleConfirmChangelog = async () => {
		try {
			// Enregistrer dans le localStorage
			localStorage.setItem(`changelog_${changelogVersion}`, 'true')

			// Enregistrer dans la base de données
			const userDocRef = doc(firestore, 'users', userId)
			await updateDoc(userDocRef, {
				changelogsRead: arrayUnion(changelogVersion)
			})

			setShowChangelogPopup(false)
		} catch (error) {
			console.error(
				"Erreur lors de l'enregistrement du changelog :",
				error
			)
		}
	}

	// Récupérer les informations utilisateur, y compris son statut, depuis Firestore
	const fetchUserStatus = async (user) => {
		if (user && !user.isAnonymous) {
			try {
				const userDoc = await getDoc(doc(firestore, 'users', user.uid))
				if (userDoc.exists()) {
					const userData = userDoc.data()
					setUserStatus(userData.statut || '') // Met à jour le statut de l'utilisateur
					setUserLevel(userData.level || '') // Récupère le niveau de l'utilisateur
					setUserPseudo(userData.pseudo || '') // récupère le pseudo
					setUserPreferences({
						preferences: userData.preferences || [],
						city: userData.city || '',
						radius: userData.radius || 30
					})

					// Stocker les préférences dans le localStorage
					localStorage.setItem(
						'userPreferences',
						JSON.stringify(userData.preferences || [])
					)
					localStorage.setItem('userCity', userData.city || '')
					localStorage.setItem(
						'userSliderValue',
						userData.radius || 30
					)
				}
			} catch (error) {
				console.error(
					'Erreur lors de la récupération du statut utilisateur :',
					error
				)
			}
		}
	}

	// Vérifier les préférences de l'utilisateur et afficher la popup si nécessaire
	useEffect(() => {
		const hasCheckedPreferences = localStorage.getItem(
			'hasCheckedPreferences'
		)

		// Si la vérification n'a pas encore été effectuée
		if (!hasCheckedPreferences) {
			// Ajouter un délai avant l'affichage de la popup (par exemple 2000 ms = 2 secondes)
			const delay = setTimeout(() => {
				if (
					userPreferences &&
					(!userPreferences.city || userPreferences.city === '')
				) {
					setShowPreferencesPopup(true)
				} else {
					setShowPreferencesPopup(false)
				}

				// Marquer la vérification comme effectuée
				// localStorage.setItem('hasCheckedPreferences', 'true')
			}, 2000) // délai

			// Nettoyer le timeout si le composant est démonté avant la fin du délai
			return () => clearTimeout(delay)
		}
	}, [userPreferences])

	// Obtenir l'état de l'authentification de l'utilisateur
	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUserEmail(user.email || '')
				setUserId(user.uid)
				setIsEmailVerified(user.emailVerified)
				setIsAnonymous(user.isAnonymous)

				// Afficher la popup de vérification d'email si nécessaire
				if (!user.isAnonymous && !user.emailVerified) {
					setShowEmailVerificationPopup(true)
				} else {
					setShowEmailVerificationPopup(false)
				}

				// Récupérer le statut utilisateur
				fetchUserStatus(user)
			} else {
				// Réinitialiser les valeurs si l'utilisateur se déconnecte
				setUserId(null)
				setUserEmail(null)
				setIsEmailVerified(false)
				setShowEmailVerificationPopup(false)
				setUserStatus('')
			}
		})
		return () => unsubscribe()
	}, [])

	// Fonction pour calculer si un lieu est dans un rayon donné (utilisée pour filtrer les lieux)
	const isWithinRadius = (lat1, lon1, lat2, lon2, radius) => {
		const earthRadius = 6371 // Rayon de la Terre en km
		const dLat = ((lat2 - lat1) * Math.PI) / 180
		const dLon = ((lon2 - lon1) * Math.PI) / 180
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos((lat1 * Math.PI) / 180) *
				Math.cos((lat2 * Math.PI) / 180) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2)
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
		const distance = earthRadius * c // Distance en km

		return distance <= radius
	}

	//Détecter les appareils mobiles
	const isMobileDevice = () => {
		return /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(
			navigator.userAgent
		)
	}

	// Récupérer les lieux dans un rayon de 30 km autour de la position de l'utilisateur
	const fetchHeritageItems = useCallback(
		async (bounds = null, centerCoords = null) => {
			let userLat = null
			let userLon = null

			// Si des coordonnées centrales sont fournies, les utiliser
			if (centerCoords) {
				userLat = centerCoords.latitude
				userLon = centerCoords.longitude
			}

			// Si aucune limite n'est fournie, déterminer la position de l'utilisateur
			if (!centerCoords && !bounds) {
				const actualCity = localStorage.getItem('ActualCity')

				if (actualCity) {
					try {
						// Utiliser la ville actuelle pour obtenir les coordonnées
						const cityCoords = await geocodeCity(actualCity)
						userLat = cityCoords.latitude
						userLon = cityCoords.longitude
						setUserLocation({ userLat, userLon })
					} catch (error) {
						console.error(
							'Erreur lors du géocodage de ActualCity :',
							error
						)
					}
				}

				if ((!userLat || !userLon) && geoLocation) {
					if (isMobileDevice()) {
						try {
							// Essayer d'obtenir la position GPS de l'utilisateur
							const location = await getUserLocation()
							userLat = location.latitude
							userLon = location.longitude
							setUserLocation({ userLat, userLon })
						} catch (error) {
							// Désactiver la géolocalisation si elle n'est pas disponible
							setGeoLocation(false)
							console.error(
								'Géolocalisation indisponible :',
								error
							)
						}
					}
				}

				if ((!userLat || !userLon) && userPreferences.city) {
					try {
						// Utiliser la ville préférée de l'utilisateur
						const cityCoords = await geocodeCity(
							userPreferences.city
						)
						userLat = cityCoords.latitude
						userLon = cityCoords.longitude
						setUserLocation({ userLat, userLon })
					} catch (error) {
						console.error(
							'Erreur lors du géocodage de la ville préférée :',
							error
						)
					}
				}

				if (!userLat || !userLon) {
					// Utiliser une position par défaut si aucune autre n'est disponible
					console.error(
						'Ni la géolocalisation ni la ville ne sont disponibles, utilisation de Le Quesnoy par défaut'
					)
					userLat = 50.247342
					userLon = 3.63645
					setUserLocation({ userLat, userLon })
				}
			}

			setLoadingLocation(false)

			// Récupérer les lieux depuis Firestore
			const heritageCollection = collection(firestore, 'heritage')
			const snapshot = await getDocs(heritageCollection)
			const elements = snapshot.docs.map((doc) => ({
				...doc.data(),
				id: doc.id
			}))

			let filteredElements = []

			// Vérifier si `bounds` est défini avant de l'utiliser
			if (bounds && bounds.northEast && bounds.southWest) {
				// Calculer le centre des bounds
				const centerLat =
					(bounds.northEast[1] + bounds.southWest[1]) / 2
				const centerLon =
					(bounds.northEast[0] + bounds.southWest[0]) / 2

				// Filtrer les lieux dans un rayon de 30 km autour du centre des bounds
				filteredElements = elements.filter((item) => {
					const [longitude, latitude] = item.geometry.coordinates
					return isWithinRadius(
						centerLat,
						centerLon,
						latitude,
						longitude,
						30
					)
				})
			} else if (userLat && userLon) {
				// Filtrer les lieux en fonction du rayon autour de la position de l'utilisateur
				filteredElements = elements.filter((item) => {
					const [longitude, latitude] = item.geometry.coordinates
					return isWithinRadius(
						userLat,
						userLon,
						latitude,
						longitude,
						30
					)
				})
			} else {
				// Si aucune position valide n'est disponible, afficher un message d'alerte
				console.alert('Aucun lieu disponible')
			}

			// Mettre à jour l'état avec les éléments filtrés
			setAllHeritageItems(filteredElements)
		},
		[userPreferences, geoLocation]
	)

	// Récupérer les événements depuis Firestore (similaire à votre fetchEvents)
	const fetchEvents = useCallback(
		async (bounds = null, centerCoords = null) => {
			let userLat = null
			let userLon = null

			if (centerCoords) {
				userLat = centerCoords.latitude
				userLon = centerCoords.longitude
			}

			if (!centerCoords && !bounds) {
				const actualCity = localStorage.getItem('ActualCity')
				if (actualCity) {
					try {
						const cityCoords = await geocodeCity(actualCity)
						userLat = cityCoords.latitude
						userLon = cityCoords.longitude
						setUserLocation({ userLat, userLon })
					} catch (error) {
						console.error(
							'Erreur lors du géocodage de ActualCity :',
							error
						)
					}
				}
				if ((!userLat || !userLon) && geoLocation) {
					if (isMobileDevice()) {
						try {
							const location = await getUserLocation()
							userLat = location.latitude
							userLon = location.longitude
							setUserLocation({ userLat, userLon })
						} catch (error) {
							setGeoLocation(false)
							console.error(
								'Géolocalisation indisponible :',
								error
							)
						}
					}
				}
				if ((!userLat || !userLon) && userPreferences.city) {
					try {
						const cityCoords = await geocodeCity(
							userPreferences.city
						)
						userLat = cityCoords.latitude
						userLon = cityCoords.longitude
						setUserLocation({ userLat, userLon })
					} catch (error) {
						console.error(
							'Erreur lors du géocodage de la ville préférée :',
							error
						)
					}
				}
				if (!userLat || !userLon) {
					userLat = 50.247342
					userLon = 3.63645
					setUserLocation({ userLat, userLon })
				}
			}

			setLoadingLocation(false)

			// Contrainte de date : récupérer uniquement les événements dont
			// displayBeforeStartDate <= aujourd'hui et endDate >= aujourd'hui.
			const now = new Date()
			const eventsQuery = query(
				collection(firestore, 'event'),
				where('properties.displayBeforeStartDate', '<=', now),
				where('properties.endDate', '>=', now)
			)

			const snapshot = await getDocs(eventsQuery)
			const eventsData = snapshot.docs.map((doc) => ({
				...doc.data(),
				id: doc.id
			}))

			let filteredElements = []

			// Vérifier si `bounds` est défini avant de l'utiliser
			if (bounds && bounds.northEast && bounds.southWest) {
				// Calculer le centre des bounds
				const centerLat =
					(bounds.northEast[1] + bounds.southWest[1]) / 2
				const centerLon =
					(bounds.northEast[0] + bounds.southWest[0]) / 2

				// Filtrer les lieux dans un rayon de 30 km autour du centre des bounds
				filteredElements = eventsData.filter((item) => {
					const [longitude, latitude] = item.geometry.coordinates
					return isWithinRadius(
						centerLat,
						centerLon,
						latitude,
						longitude,
						30
					)
				})
			} else if (userLat && userLon) {
				// Filtrer les lieux en fonction du rayon autour de la position de l'utilisateur
				filteredElements = eventsData.filter((item) => {
					const [longitude, latitude] = item.geometry.coordinates
					return isWithinRadius(
						userLat,
						userLon,
						latitude,
						longitude,
						30
					)
				})
			} else {
				// Si aucune position valide n'est disponible, afficher un message d'alerte
				console.alert('Aucun lieu disponible')
			}
			setAllEvents(filteredElements)
		},
		[userPreferences, geoLocation]
	)

	// Calculer les événements filtrés et triés via useEffect ou useMemo
	useEffect(() => {
		const filterAndSortEvents = () => {
			let filtered = allEvents

			// Filtrer par rayon
			if (userPreferences.radius && userLocation) {
				filtered = filtered.filter((item) => {
					const [longitude, latitude] = item.geometry.coordinates
					return isWithinRadius(
						userLocation.userLat,
						userLocation.userLon,
						latitude,
						longitude,
						userPreferences.radius
					)
				})
			}

			// Filtrer par tags si les filtres sont activés
			if (filtersEnabled && userPreferences.preferences?.length > 0) {
				filtered = filtered.filter((item) =>
					item.properties.tags?.some((tag) =>
						userPreferences.preferences.includes(tag)
					)
				)
			}

			// Trier par distance croissante
			if (userLocation) {
				filtered.sort((a, b) => {
					const [lonA, latA] = a.geometry.coordinates
					const [lonB, latB] = b.geometry.coordinates
					const distanceA = calculateDistance(
						userLocation.userLat,
						userLocation.userLon,
						latA,
						lonA
					)
					const distanceB = calculateDistance(
						userLocation.userLat,
						userLocation.userLon,
						latB,
						lonB
					)
					return distanceA - distanceB
				})
			}

			setDisplayEvents(filtered)
		}

		filterAndSortEvents()
	}, [allEvents, userPreferences, filtersEnabled, userLocation])

	// Fonction pour gérer la recherche de ville et recentrer la carte
	const handleCitySearch = useCallback(
		(coordinates, city) => {
			// Mettre à jour la position de l'utilisateur
			setUserLocation({
				userLat: coordinates.latitude,
				userLon: coordinates.longitude
			})

			// Mettre à jour le localStorage avec la ville actuelle
			localStorage.setItem('ActualCity', city || '')

			// Réinitialiser le lieu sélectionné
			setSelectedPlaceId(null)
			setHighlightedPlaceId(null)
			setSelectedEventId(null)
			setHighlightedEventId(null)

			// Relancer la recherche des lieux dans cette zone
			fetchHeritageItems()
			fetchEvents()
			// Ne pas appliquer les filtres ici, juste récupérer les lieux
		},
		[fetchHeritageItems, fetchEvents]
	)

	// Initialiser les données au chargement du composant
	useEffect(() => {
		fetchHeritageItems()
		fetchEvents()
	}, [fetchHeritageItems, fetchEvents])

	// Appeler la fonction fetchEvents au montage du composant
	useEffect(() => {
		fetchEvents()
	}, [fetchEvents])

	// Fonction pour centrer sur la position de l'utilisateur
	const centerOnUserLocation = useCallback(async () => {
		// Réinitialiser 'ActualCity' dans le localStorage
		localStorage.removeItem('ActualCity')

		// Réinitialiser le lieu sélectionné
		setSelectedPlaceId(null)
		setHighlightedPlaceId(null)
		setSelectedEventId(null)
		setHighlightedEventId(null)

		try {
			// Essayer d'obtenir la position GPS de l'utilisateur
			const location = await getUserLocation()
			setUserLocation({
				userLat: location.latitude,
				userLon: location.longitude
			})

			// Récupérer les lieux autour de la position GPS de l'utilisateur
			fetchHeritageItems(null, location)
			fetchEvents(null, location)
		} catch (error) {
			// Si la position GPS n'est pas disponible, recentrer sur la ville préférée de l'utilisateur
			if (userPreferences.city && userPreferences.city !== '') {
				try {
					const cityCoords = await geocodeCity(userPreferences.city)
					setUserLocation({
						userLat: cityCoords.latitude,
						userLon: cityCoords.longitude
					})
					// Récupérer les lieux autour de la ville
					fetchHeritageItems(null, cityCoords)
					fetchEvents(null, cityCoords)
				} catch (geoError) {
					console.error(
						'Erreur lors du géocodage de la ville :',
						geoError
					)
				}
			} else {
				// Supprimer 'hasCheckedPreferences' du localStorage
				localStorage.removeItem('hasCheckedPreferences')

				// Aucune position disponible, afficher la popup des préférences
				setShowPreferencesPopup(true)
			}
		}
	}, [fetchHeritageItems, userPreferences, fetchEvents])

	// Fonction pour calculer le centre des limites de la carte
	const getCenterOfBounds = (northEast, southWest) => {
		const lat = (northEast[1] + southWest[1]) / 2
		const lon = (northEast[0] + southWest[0]) / 2
		return { lat, lon }
	}

	// Fonction pour mettre à jour les limites de la carte
	const handleSetMapBounds = useCallback((bounds) => {
		setMapBounds(bounds)
	}, [])

	// Fonction pour rechercher les lieux dans la zone visible de la carte
	const searchWithinMapBounds = useCallback(async () => {
		if (!mapBounds) return

		// Réinitialiser 'ActualCity' dans le localStorage
		localStorage.removeItem('ActualCity')

		// Réinitialiser le lieu sélectionné
		setSelectedPlaceId(null)
		setHighlightedPlaceId(null)
		setSelectedEventId(null)
		setHighlightedEventId(null)

		setActiveMenu('menu1')
		setActiveTab('places')

		const { northEast, southWest } = mapBounds
		const center = getCenterOfBounds(northEast, southWest)

		// Mettre à jour la position de l'utilisateur avec le centre de la carte
		setUserLocation({
			userLat: center.lat,
			userLon: center.lon
		})

		// Récupérer les lieux dans les limites de la carte
		fetchHeritageItems(mapBounds)
		fetchEvents(mapBounds)
	}, [mapBounds, fetchHeritageItems, fetchEvents])

	// Ajoutez cette fonction utilitaire pour calculer la distance en km entre deux coordonnées
	const calculateDistance = (lat1, lon1, lat2, lon2) => {
		const toRad = (value) => (value * Math.PI) / 180
		const R = 6371 // Rayon de la Terre en km
		const dLat = toRad(lat2 - lat1)
		const dLon = toRad(lon2 - lon1)
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(toRad(lat1)) *
				Math.cos(toRad(lat2)) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2)
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
		return R * c
	}

	useEffect(() => {
		const filterAndSortItems = () => {
			let filteredItems = allHeritageItems

			// Filtrer selon le rayon défini par l'utilisateur
			if (userPreferences.radius && userLocation) {
				filteredItems = filteredItems.filter((item) => {
					const [longitude, latitude] = item.geometry.coordinates
					return isWithinRadius(
						userLocation.userLat,
						userLocation.userLon,
						latitude,
						longitude,
						userPreferences.radius
					)
				})
			}

			// Filtrer selon les tags
			if (filtersEnabled && userPreferences.preferences?.length > 0) {
				filteredItems = filteredItems.filter((item) =>
					item.properties.tags?.some((tag) =>
						userPreferences.preferences.includes(tag)
					)
				)
			}

			// Trier les lieux du plus proche au plus éloigné
			if (userLocation) {
				filteredItems.sort((a, b) => {
					const [lonA, latA] = a.geometry.coordinates
					const [lonB, latB] = b.geometry.coordinates
					const distanceA = calculateDistance(
						userLocation.userLat,
						userLocation.userLon,
						latA,
						lonA
					)
					const distanceB = calculateDistance(
						userLocation.userLat,
						userLocation.userLon,
						latB,
						lonB
					)
					return distanceA - distanceB
				})
			}

			setHeritageItems(filteredItems)
		}

		filterAndSortItems()
	}, [userPreferences, filtersEnabled, allHeritageItems, userLocation])

	// Fonction pour mettre à jour les préférences utilisateur
	const handlePreferencesUpdate = (updatedPreferences) => {
		setUserPreferences(updatedPreferences)
		setShowPreferencesPopup(false)
	}

	// Fonction pour gérer la sélection d'un lieu et afficher plus de détails
	const handleSeeMorePlace = (placeOrId) => {
		// Si l'objet complet est passé, on l'utilise directement
		let placeData =
			typeof placeOrId === 'object'
				? placeOrId
				: heritageItems.find((p) => p.id === placeOrId)

		if (placeData && placeData.geometry && placeData.geometry.coordinates) {
			const [longitude, latitude] = placeData.geometry.coordinates
			// Mettre à jour la position pour recentrer la carte sur la place
			setUserLocation({
				userLat: latitude,
				userLon: longitude
			})

			fetchHeritageItems(null, { latitude, longitude })
			fetchEvents(null, { latitude, longitude })
		}

		// Définir la place sélectionnée et déclencher le recentrage
		const placeId = placeData ? placeData.id : placeOrId
		setSelectedPlaceId(placeId)
		setHighlightedPlaceId(placeId)
		setSelectedEventId(null)
		setHighlightedEventId(null)
		setCenterOnPlaceTrigger((prev) => prev + 1)
		setActiveTab('places')
		setActiveMenu('menu2')
	}

	// Fonction pour gérer la sélection d'un lieu et afficher plus de détails
	const handleSeeMoreEvent = (placeOrId) => {
		// Si l'objet complet est passé, on l'utilise directement
		let eventData =
			typeof placeOrId === 'object'
				? placeOrId
				: displayEvents.find((p) => p.id === placeOrId)

		if (eventData && eventData.geometry && eventData.geometry.coordinates) {
			const [longitude, latitude] = eventData.geometry.coordinates
			// Mettre à jour la position pour recentrer la carte sur la place
			setUserLocation({
				userLat: latitude,
				userLon: longitude
			})

			fetchHeritageItems(null, { latitude, longitude })
			fetchEvents(null, { latitude, longitude })
		}

		// Définir la place sélectionnée et déclencher le recentrage
		const eventId = eventData ? eventData.id : placeOrId
		setSelectedEventId(eventId)
		setHighlightedEventId(eventId)
		setSelectedPlaceId(null)
		setHighlightedPlaceId(null)
		setCenterOnPlaceTrigger((prev) => prev + 1)
		setActiveTab('events')
		setActiveMenu('menu2')
	}

	// Fonction pour renvoyer l'email de vérification
	const handleResendVerificationEmail = () => {
		const user = auth.currentUser
		if (user) {
			sendEmailVerification(user)
				.then(() => alert('Email de vérification renvoyé'))
				.catch((error) =>
					console.error(
						'Erreur lors de l’envoi de l’email de vérification :',
						error
					)
				)
		} else {
			console.error(
				"L'utilisateur n'est pas connecté ou n'est pas accessible"
			)
		}
	}

	// Fonction pour accéder à la console d'administration
	const handleAdmin = () => {
		navigate('/admin-WeaselMap-console') // Redirection vers la page d'administration
	}

	// Fonction pour accéder à la page de gestion proWeaselSpace
	const handlePro = () => {
		navigate('/WeaselSpace', {
			state: {
				userId: userId,
				userEmail: userEmail,
				userStatus: userStatus,
				userPseudo: userPseudo
			}
		}) // Redirection vers la page d'administration
	}

	return (
		<div className="home">
			{/* Afficher la carte une fois la localisation chargée */}
			{loadingLocation ? (
				<Loading />
			) : (
				<>
					{/* Popup de vérification d'email */}
					{showEmailVerificationPopup && (
						<Popup
							text="Pour accéder à toutes les fonctionnalités, merci de vérifier votre adresse email."
							onAction={handleResendVerificationEmail}
							isVisible={showEmailVerificationPopup}
							actionLabel="Renvoyer l'email de confirmation"
							onClose={() => setShowEmailVerificationPopup(false)}
							className={'email'}
						/>
					)}

					{/* Popup des préférences utilisateur */}
					{showPreferencesPopup && (
						<Popup
							text="Pour accéder à toutes les fonctionnalités, indiquez-nous vos préférences !"
							isVisible={showPreferencesPopup}
							onClose={() => setShowPreferencesPopup(false)}
							className="preferences"
						>
							<TagsBox
								userId={userId}
								userPreferences={userPreferences}
								onPreferencesUpdate={handlePreferencesUpdate}
								isAnonymous={isAnonymous}
								onClose={() => setShowPreferencesPopup(false)}
							/>
						</Popup>
					)}

					{/* Popup de changelog */}
					{showChangelogPopup && (
						<Popup
							text="Bienvenue dans cette première version !"
							onAction={handleConfirmChangelog}
							actionLabel="J'ai lu"
							isVisible={showChangelogPopup}
							expireDate={changelogExpireDate}
							onClose={() => setShowChangelogPopup(false)}
							className={'changelog'}
						>
							<div>
								<p className="popup__main__title">
									Mais surtout : merci d'avoir rejoint
									l'aventure <strong>WeaselMap</strong> ! 🎉
								</p>
								<br />
								<p>
									<strong>WeaselMap</strong>, c'est une
									application conçue pour promouvoir les lieux
									proches de chez vous, favoriser le tourisme
									de proximité et vous aider à redécouvrir et
									partager notre patrimoine local. Que ce soit
									pour une balade, une activité en famille ou
									un moment de détente,{' '}
									<strong>WeaselMap</strong> souhaite répondre
									à cette éternelle question :{' '}
									<em>"On fait quoi demain ?"</em>
								</p>
								<p>
									Cette première version publique (Beta V0.1)
									marque le début d'une expérience
									participative où chaque utilisateur joue un
									rôle essentiel.
								</p>
								<ul>
									<li>
										<strong>
											Partagez vos lieux favoris :
										</strong>{' '}
										Aidez-nous à constituer une base de
										données riche et diversifiée en ajoutant
										vos endroits préférés. Chaque
										contribution compte !
									</li>
									<br />
									<li>
										<strong>
											Partagez votre expérience :
										</strong>{' '}
										Identifiez des bugs ou proposez des
										idées d'amélioration. Votre retour est
										précieux pour perfectionner WeaselMap.
									</li>
								</ul>
								<p>
									Pour toute question ou suggestion, n'hésitez
									pas à utiliser l'outil de contact intégré.
									Ensemble, construisons une application qui
									répond à vos besoins !
								</p>
								<p>
									Mille mercis pour votre soutien et votre
									participation à cette aventure. 🚀
								</p>
								<div className="popup__main__signature">
									Christian
								</div>
							</div>
						</Popup>
					)}

					{/* Composant principal de la carte */}
					<MapMain
						data={heritageItems}
						dataEvent={displayEvents}
						setActiveMenu={setActiveMenu}
						setActiveTab={setActiveTab}
						setSelectedPlaceId={(id) => {
							setSelectedPlaceId(id)
							setHighlightedPlaceId(id)
						}}
						setSelectedEventId={(id) => {
							setSelectedEventId(id)
							setHighlightedEventId(id)
						}}
						highlightedPlaceId={highlightedPlaceId}
						highlightedEventId={highlightedEventId}
						mapCenter={mapCenter}
						centerOnPlaceTrigger={centerOnPlaceTrigger}
						onBoundsChange={handleSetMapBounds}
						userLocation={userLocation}
						userPreferences={userPreferences}
					/>

					{/* Panneau de commandes */}
					<MapControls
						userStatus={userStatus}
						userEmail={userEmail}
						handleAdmin={handleAdmin}
						handlePro={handlePro}
						isEmailVerified={isEmailVerified}
						openModal={openModal}
						filtersEnabled={filtersEnabled}
						setFiltersEnabled={setFiltersEnabled}
						searchWithinMapBounds={searchWithinMapBounds}
						centerOnUserLocation={centerOnUserLocation}
					/>

					{/* Menu de navigation principal */}
					<MenuNav
						data={heritageItems}
						events={displayEvents}
						userId={userId}
						userEmail={userEmail}
						userPseudo={userPseudo}
						userLevel={userLevel}
						isEmailVerified={isEmailVerified}
						isAnonymous={isAnonymous}
						filtersEnabled={filtersEnabled}
						setFiltersEnabled={setFiltersEnabled}
						userPreferences={userPreferences}
						onPreferencesUpdate={handlePreferencesUpdate}
						resendVerificationEmail={handleResendVerificationEmail}
						activeMenu={activeMenu}
						setActiveMenu={setActiveMenu}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						selectedPlaceId={selectedPlaceId}
						setSelectedPlaceId={handleSeeMorePlace}
						selectedEventId={selectedEventId}
						setSelectedEventId={handleSeeMoreEvent}
						userStatus={userStatus}
						onCitySearch={handleCitySearch}
						setMapCenter={setMapCenter}
						userLocation={userLocation}
						handleAdmin={handleAdmin}
						handlePro={handlePro}
					/>
				</>
			)}

			{/* Modal pour le formulaire d'ajout de lieu */}
			<Modal
				isOpen={isModalOpen}
				onClose={closeModal}
				className={'addPlaceForm__modal'}
			>
				<AddPlaceForm
					onClose={closeModal}
					userStatus={userStatus}
					userEmail={userEmail}
					userId={userId}
					existingData={null}
					mode={'creation'}
				/>
			</Modal>
		</div>
	)
}

export default Home
