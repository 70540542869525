import React, { useState, useEffect } from 'react'
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore'
import { firestore } from '../../../services/firebaseConfig'

import EventCard from '../../Objects/Event_card'

import belette from '../../../assets/beletteMarron256.png'

const EventHistory = ({ userId, userStatus, userEmail }) => {
	const [events, setEvents] = useState([])
	const [loading, setLoading] = useState(true)
	const [refresh, setRefresh] = useState(false)

	useEffect(() => {
		const fetchEvents = async () => {
			try {
				// Référence à la collection "event" filtrée par userId et triée par date de début décroissante
				const eventsRef = collection(firestore, 'event')
				const q = query(
					eventsRef,
					where('properties.userId', '==', userId),
					orderBy('properties.startDate', 'desc')
				)
				const querySnapshot = await getDocs(q)
				const eventsList = []
				querySnapshot.forEach((doc) => {
					eventsList.push({ id: doc.id, ...doc.data() })
				})
				setEvents(eventsList)
			} catch (err) {
				console.error(
					'Erreur lors de la récupération des événements :',
					err
				)
			} finally {
				setLoading(false)
			}
		}

		if (userId) {
			fetchEvents()
		}
	}, [userId, refresh])

	// Fonction de rappel à passer au composant enfant pour rafraîchir la liste
	const handleRefresh = () => {
		setRefresh((prev) => !prev)
	}

	if (loading) return <h3>Chargement...</h3>

	const now = Date.now()

	// Filtrer les événements en cours ou à venir
	const ongoingEvents = events.filter((event) => {
		const eventEnd = event.properties.endDate.seconds * 1000
		return eventEnd >= now
	})
	// Filtrer les événements passés
	const pastEvents = events.filter((event) => {
		const eventEnd = event.properties.endDate.seconds * 1000
		return eventEnd < now
	})

	// Trier les événements : pour les événements à venir par ordre croissant (date la plus proche en premier)
	const sortedOngoingEvents = [...ongoingEvents].sort(
		(a, b) =>
			a.properties.startDate.seconds - b.properties.startDate.seconds
	)
	// Pour les événements passés par ordre décroissant (le plus récent en premier)
	const sortedPastEvents = [...pastEvents].sort(
		(a, b) =>
			b.properties.startDate.seconds - a.properties.startDate.seconds
	)

	// Fonction de rendu qui insère un séparateur d'année.
	// skipCurrentYearSeparator indique si, pour le premier groupe, l'année courante doit être masquée.
	const renderEventsWithYearSeparator = (
		eventsList,
		skipCurrentYearSeparator = false
	) => {
		let lastYear = null
		const currentYear = new Date().getFullYear()
		return eventsList.map((event) => {
			const eventYear = new Date(
				event.properties.startDate.seconds * 1000
			).getFullYear()
			let separator = null
			// Si c'est le premier événement (lastYear === null) et que skipCurrentYearSeparator est vrai
			// et que l'année de l'événement est l'année courante, on ne crée pas de séparateur.
			if (
				lastYear === null &&
				skipCurrentYearSeparator &&
				eventYear === currentYear
			) {
				separator = null
			} else if (eventYear !== lastYear) {
				separator = (
					<div
						key={`separator-${eventYear}`}
						className="year-separator"
					>
						<h2>{eventYear}</h2>
					</div>
				)
			}
			lastYear = eventYear
			return (
				<React.Fragment key={event.id}>
					{separator}
					<div className="event__history__item">
						<EventCard
							className={'eventCard-history'}
							data={[event]}
							isEmailVerified={true}
							userStatus={userStatus}
							userId={userId}
							userEmail={userEmail}
							isAnonymous={false}
							onRefresh={handleRefresh}
						/>
						<div className="event__history__item__infos">
							<div>
								Evénement du{' '}
								<span>
									{new Date(
										event.properties.startDate.seconds *
											1000
									).toLocaleDateString()}{' '}
								</span>{' '}
								{event.properties.startDate.seconds !==
									event.properties.endDate.seconds && (
									<span>
										au{' '}
										{new Date(
											event.properties.endDate.seconds *
												1000
										).toLocaleDateString()}
									</span>
								)}
							</div>
							<div>
								Début du partage :{' '}
								<span>
									{new Date(
										event.properties.displayBeforeStartDate
											.seconds * 1000
									).toLocaleDateString()}{' '}
								</span>
							</div>
						</div>
					</div>
				</React.Fragment>
			)
		})
	}

	if (events.length === 0)
		return (
			<div className="event__history__noEvent">
				<h3>Aucun événement passé ou en cours.</h3>
				Partant pour créer votre premier événement ?
				<img src={belette} alt="Belette de WeaselMap" />
			</div>
		)

	return (
		<div className="event__history__list">
			{sortedOngoingEvents.length > 0 && (
				<div className="event__history__list-ongoing-events">
					<h2>Événements en cours ou à venir</h2>
					<div className="ongoing-events">
						{renderEventsWithYearSeparator(
							sortedOngoingEvents,
							true
						)}
					</div>
				</div>
			)}

			{sortedPastEvents.length > 0 && (
				<div className="event__history__list-past-events">
					<h2>Événements passés</h2>
					<div className="past-events">
						{renderEventsWithYearSeparator(sortedPastEvents)}
					</div>
				</div>
			)}
		</div>
	)
}

export default EventHistory
