import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
	doc,
	updateDoc,
	arrayUnion,
	arrayRemove,
	getDoc
} from 'firebase/firestore'
import { firestore } from '../../../services/firebaseConfig'

const AddToFavoritesButton = ({
	userId,
	placeId,
	eventId,
	isAnonymous,
	onFavoritesChange,
	variant
}) => {
	const [isFavorite, setIsFavorite] = useState(false)

	useEffect(() => {
		const checkIfFavorite = async () => {
			if (!placeId) return

			// Gestion des favoris pour les utilisateurs anonymes
			if (isAnonymous) {
				const storedFavorites =
					JSON.parse(localStorage.getItem('anonymous_favorites')) ||
					[]
				setIsFavorite(storedFavorites.includes(placeId))
				return
			}

			// Gestion des favoris pour les utilisateurs connectés
			if (userId) {
				const storedFavorites =
					JSON.parse(localStorage.getItem(`favorites_${userId}`)) ||
					[]
				if (storedFavorites.includes(placeId)) {
					setIsFavorite(true)
					return
				}

				try {
					const userDoc = await getDoc(
						doc(firestore, 'users', userId)
					)
					if (userDoc.exists()) {
						const userFavorites = userDoc.data().favorites || []
						setIsFavorite(userFavorites.includes(placeId))

						// Mettre à jour localStorage avec les favoris
						localStorage.setItem(
							`favorites_${userId}`,
							JSON.stringify(userFavorites)
						)
					}
				} catch (error) {
					console.error(
						'Erreur lors de la récupération des favoris :',
						error
					)
				}
			}
		}

		checkIfFavorite()
	}, [userId, placeId, isAnonymous])

	const toggleFavorite = async () => {
		if (!placeId) return

		// Gestion des favoris pour les utilisateurs anonymes
		if (isAnonymous) {
			const storedFavorites =
				JSON.parse(localStorage.getItem('anonymous_favorites')) || []

			if (isFavorite) {
				// Retirer des favoris
				const updatedFavorites = storedFavorites.filter(
					(id) => id !== placeId
				)
				localStorage.setItem(
					'anonymous_favorites',
					JSON.stringify(updatedFavorites)
				)
				onFavoritesChange && onFavoritesChange(updatedFavorites)
			} else {
				// Ajouter aux favoris
				const updatedFavorites = [...storedFavorites, placeId]
				localStorage.setItem(
					'anonymous_favorites',
					JSON.stringify(updatedFavorites)
				)
			}

			setIsFavorite(!isFavorite)
			return
		}

		// Gestion des favoris pour les utilisateurs connectés
		if (userId) {
			const userRef = doc(firestore, 'users', userId)

			try {
				const storedFavorites =
					JSON.parse(localStorage.getItem(`favorites_${userId}`)) ||
					[]

				if (isFavorite) {
					// Retirer des favoris
					await updateDoc(userRef, {
						favorites: arrayRemove(placeId)
					})

					const updatedFavorites = storedFavorites.filter(
						(id) => id !== placeId
					)
					localStorage.setItem(
						`favorites_${userId}`,
						JSON.stringify(updatedFavorites)
					)
				} else {
					// Ajouter aux favoris
					await updateDoc(userRef, {
						favorites: arrayUnion(placeId)
					})

					const updatedFavorites = [...storedFavorites, placeId]
					localStorage.setItem(
						`favorites_${userId}`,
						JSON.stringify(updatedFavorites)
					)
				}

				setIsFavorite(!isFavorite)
			} catch (error) {
				console.error(
					'Erreur lors de la mise à jour des favoris :',
					error
				)
			}
		}
	}

	return (
		<>
			{variant === 'aroundYou' ? (
				isFavorite && (
					<span
						className={`material-symbols-outlined favoriteBtn-remove favoriteBtn`}
					>
						favorite
					</span>
				)
			) : (
				<button
					className={`favoriteBtn`}
					onClick={toggleFavorite}
					title={
						isFavorite
							? 'Retirer des favoris'
							: 'Ajouter aux favoris'
					}
				>
					<span
						className={
							isFavorite
								? `material-symbols-outlined favoriteBtn-remove`
								: 'material-symbols-outlined favoriteBtn-add'
						}
					>
						favorite
					</span>
				</button>
			)}
		</>
	)
}

AddToFavoritesButton.propTypes = {
	userId: PropTypes.string.isRequired,
	placeId: PropTypes.string,
	variant: PropTypes.string
}

export default AddToFavoritesButton
